import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-building="114D"
        data-floor="0"
        points="69 953 81 994 212 947 219 956 339 910 337 898 450 856 444 823 330 862 331 874 211 914 205 906 69 953"
      />
      <polygon
        data-building="114D"
        data-floor="1"
        points="69 953 205 906 211 914 331 874 330 862 444 823 437 777 321 816 322.25 823 200 864 198 855 56 902 69 953"
      />
      <polygon
        data-building="114D"
        data-floor="2"
        points="56 902 198 855 200 864 322.25 823 321 816 437 777 430 729 313 766 314 774 188 814 186 804 42 852 56 902"
      />
      <polygon
        data-building="114D"
        data-floor="3"
        points="42 852 186 804 188 814 314 774 313 766 430 729 422 680 303 718 304 724 177 763 175 755 30 801 42 852"
      />
      <polygon
        data-building="114D"
        data-floor="4"
        points="30 801 175 755 177 763 304 724 303 718 422 680 414 626 293 660 294 666 164 708 162 701 15 744 30 801"
      />
      <polygon
        data-building="114D"
        data-floor="5"
        points="15 744 162 701 164 708 294 666 293 660 414 626 407 577 285 612 286 618 153 656 151 647 0 690.21 15 744"
      />
      <polygon
        data-building="114D"
        data-floor="6"
        points="0 690.21 151 647 153 656 286 618 285 612 407 577 399 523 274 557 275 561 140 598 139 593 0 630.53 0 690.21"
      />
      <polygon
        data-building="114D"
        data-floor="7"
        points="0 630.53 139 593 140 598 275 561 274 557 399 523 390 447 314 364 214 388 213 381 208 375 201 374 32 414 35 430 0 437.25 0 630.53"
      />
      <polygon
        data-building="114C"
        data-floor="0"
        points="527 706 560 745 589 739 600 750 602 769 641 812 682.47 796.64 722 782 722 769.59 1220 586 1247 593 1256.1 586 1375 626 1375 633.18 1514 683 1520 677 1574 694 1649 607 1649 584 1578 661 1526 644 1519 650 1380 603 1380 595 1257 554 1248 561 1223 552 1107 591 750 719 758 727 758 746 669 778 654 762 637 768 585 717 558 723 525 688 527 706"
      />
      <polygon
        data-building="114C"
        data-floor="1"
        points="525 688 558 723 585 717 637 768 654 762 669 778 758 746 758 727 750 719 1223 552 1248 561 1257 554 1380 595 1380 603 1519 650 1526 644 1578 661 1649 584 1650 572 1656 563 1658 550 1654 548 1587 613 1533 598 1527 603 1384 557 1384 549 1267 511 1253 517 1226 509 745 673 759 685 759 704 665 734 651 721 634 726 583 676 553 681 521 654 518 634 510 636 511 655 522 664 525 688"
      />
      <polygon
        data-building="114C"
        data-floor="2"
        points="521 654 553 681 583 676 634 726 651 721 665 734 759 704 759 685 745 673 1226 509 1253 517 1267 511 1384 549 1384 557 1527 603 1533 598 1587 613 1654 548 1657 530 1664 523 1666 508 1660.85 508 1594 570 1539 554 1532 560 1389 520 1389 512 1270 475 1255 480 1226 471 747 631 755 640 756 660 663 689 647 675 629 680 578 635 548 642 515 605 514 591 505 593 506 610 517 621 518 634 521 654"
      />
      <polygon
        data-building="114C"
        data-floor="3"
        points="514 591 515 605 548 642 578 635 629 680 647 675 663 689 756 660 755 640 747 631 1226 471 1255 480 1270 475 1389 512 1389 520 1532 560 1539 554 1594 570 1660.85 508 1663.95 508 1665 489 1671 482 1673 467 1669.24 467 1601 524 1546 512 1539 516 1393 474 1394 465 1273 429 1259 434 1232 427 745 586 753 592 754 612 657 642 643 628 624 632 574 588 543 594 510 559.94 508 547 499 550 502 569 512 579 512.91 591.24 514 591"
      />
      <polygon
        data-building="114C"
        data-floor="4"
        points="508 547 510 559.94 543 594 574 588 624 632 643 628 657 642 754 612 753 592 745 586 1232 427 1259 434 1273 429 1394 465 1393 474 1539 516 1546 512 1601 524 1669.24 467 1673 446.92 1679 440 1681 424 1679.01 424 1681 403.69 1686 399 1686 395.74 1607 375 1607 366 1599 361 1545 346 1543 349 1482 334 1475 331 1472 334 1468 334 1462 337 1459 340 1459 365 1399 411 1272 376 1262 378 1262 388 1239 383 743 538 751 546 751 562 653 593 640 580 620 584 569 538 538 542 504 513 503 502 494 505 497 524 506.56 533 508 547"
      />
      <polygon
        data-building="114C"
        data-floor="5"
        points="503 502 504 513 538 542 569 538 620 584 640 580 653 593 751 562 751 546 743 538 1239 383 1262 388 1262 378 1272 376 1399 411 1459 365 1459 340 1462 337 1399 321 1399 319 1392 324 1368 318 1321 344 1275 335 1265 338 1265 344 1237 339 740 489 747 496 748 513 649 544 634 530 616 534 563 489 532 493 499 467.07 498 457 489 459 490 476 501 488 503 502"
      />
      <polygon
        data-building="114C"
        data-floor="6"
        points="498 457 499 467.07 532 493 563 489 616 534 634 530 649 544 748 513 747 496 740 489 1237 339 1265 344 1265 338 1275 335 1321 344 1368 318 1392 324 1418 305 1419 291 1403 286 1409 232 1294 206 1294 202 1218 184 1166 197 1171 199 1171 223 1201 240 1200 256 1236 273 1241 277 1241 293 729 432 748 449 748 465 646 493 631 480 611 482 556 433 526 438 492 411 483 413 485 432 495 442 498 457"
      />
      <polygon
        data-building="114C"
        data-floor="7"
        points="492 411 526 438 556 433 611 482 631 480 646 493 748 465 748 449 729 432 1241 293 1241 277 1236 273 1200 256 1201 240 1171 223 1171 199 1162 195 1162 192 1158.94 192 1159 195 1149 197 1149 200 1133 205 1089.95 184 1085.94 184 1022 200 1018 200 1004 192 998 192 897 215 898 227 791 253 785 248 767 253 758 247 546 296 547 309 480 325 480 329.07 474 330 474 333.52 484 343 486 363 477 365 480 384 489 394 492 411"
      />
    </svg>
  )
}

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
}

export default Map
