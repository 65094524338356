import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-floor="0"
        data-building="114E"
        points="322 387 464 457 464 465.28 496 481 505 477 586 520 607 514 640 530 640 539.76 744 593 751 591 758 596 825 575 825 561.9 878 546 884 549 906 544 906 571.68 745 620 641 567 641 556 611 540 588 544 506.62 503.5 497 505 466 488 466 481 322 406.54 322 387"
      />

      <polygon
        data-floor="1"
        data-building="114E"
        points="299 382 314 389 322 387 464 457 464 465.28 496 481 505 477 586 520 607 514 640 530 640 539.76 744 593 751 591 758 596 825 575 825 561.9 878 546 884 549 906 544 935 536 935 522.15 914 514 906 515.73 905 511 884 515 872 511 819 526 825 529 825 541 756 558 636.94 503.5 637 493 607 482 584 487 503 448 493 450 462 436 461 424 324 358 320 359 320 367 299 371 299 382"
      />

      <path
        data-floor="2"
        data-building="114E"
        d="M296,355l14,6,10-2,4-1,137,66,1,12,31,14,10-2,81,39,23-5,30,11-.06,10.5L756,558l69-17V529l-6-3,53-15,12,4,21-4,29.3-7.5V488.79L914,481l-9,2,.07-6L883,482l-10.82-5L820,491l2,1v14l-67,17L635,471V461c-4,0-31-12-31-12l-21,4-82-35-10,1-32-14V395L322,332.08h-4.91L317,341l-21,2.77Z"
        transform="translate(0 0)"
      />

      <polygon
        data-floor="3"
        data-building="114E"
        points="293 328 308 334 317.09 332.08 322 332.08 459 395 459 405 491 419 501 418 583 453 604 449 626.52 458.4 633.17 460.67 635 461 635 471 755 523 822 506 822 492 820 491 872.18 477 883 482 905.07 477 934.29 469.65 934.29 456.1 913 448 904 451 904 443 885 447 871 443 818 455 820 456 820 470 755 488 742 483 633 437 633 428 603 417 580 420 499.09 387 489.01 387 456 375 456 364 318 303 314 304 314 312 293 314.68 293 328"
      />

      <polygon
        data-floor="4"
        data-building="114E"
        points="290 301 303 306 314 304 318 303 456 364 456 375 489.01 387 499.09 387 580 420 603 417 633 428 633 437 739.67 482.02 755 488 820 470 820 456 818 455 871 443 885 447 904 443 928 436 928 422.44 904 420 904 409 883.41 413 871 409 820 422 820 435 752 451 631 404 631 393 600 384 578 385 535 367 535 362 495 346 484 348 453 335 453 329 423 315 316 275 311 276 311 286 290 289 290 301"
      />

      <polygon
        data-floor="5"
        data-building="114E"
        points="287 272 301 278 311 276 316 275 383.71 300.31 423 315 453 329 453 335 484 348 495 346 535 362 535 367 578 385 600 384 631 393 631 404 752 451 820 435 820 422 871 409 883.41 413 904 409 934.29 401.52 934.29 387.78 903 380 903 375 933 368 933 365 905.11 361.5 905 356 811 325 742 338 736 336 745.9 332.08 746 330 730 326 687 311 668 314 638 304 606 309 606 301 589 296 574 299 573.97 303 559 298 559 289 548 286 527 289 525 264 532 262 532 260 509 251 509 235 491 229 491 218 484 215 477 217 477 226 456 218 447 220 447 217 432 218 432 222 418 219 305 235 305 240 284 243 284 246.23 296 250.4 308 249 308 258 286 260 287 272"
      />

      <polygon
        data-floor="0"
        data-building="114H"
        points="1152 710 1152 674 1374 725 1380 719 1568 761 1568 769.32 1580 773 1576 780 1742 819 1749 811 1837 830 1840 825 1845 825 1845 843.65 1832 873 1703 843 1686 830 1400 765 1322 744 1317 743 1293 768 1152 710"
      />

      <polygon
        data-floor="1"
        data-building="114H"
        points="1152 674 1374 725 1380 719 1568 761 1568 769.32 1580 773 1576 780 1742 819 1749 811 1837 830 1840 825 1845 825 1870 831 1883 797 1884 784 1879 783 1877 788 1846 782 1845 787.69 1572 730 1573 722 1388 680 1380 680 1153 632 1131 649 1131 664.59 1152 668.81 1152 674"
      />

      <polygon
        data-floor="2"
        data-building="114H"
        points="1132 627 1154 631 1153 632 1282.45 659.37 1380 680 1388 680 1573 722 1572 730 1845 787.69 1846 782 1877 788 1879 783 1891 749 1891 744.59 1854 738 1576 683 1576 675 1392 639 1382 640 1155 593 1132 611.57 1132 627"
      />

      <polygon
        data-floor="3"
        data-building="114H"
        points="1134 589 1155 593 1230.96 608.73 1315.95 626.33 1382 640 1392 639 1576 675 1576 683 1854 738 1891 744.59 1902 710 1902 696.03 1891 698.67 1860 694 1580 640 1580 633 1395 598 1384 600 1157 556 1133 575 1133 589.25 1134 589"
      />

      <polygon
        data-floor="4"
        data-building="114H"
        points="1135 551 1157 554 1157 556 1347.89 593 1384 600 1395 598 1580 633 1580 640 1818.8 686.05 1860 694 1891 698.67 1905 666 1905 653.66 1899 654 1867 649 1587 598 1587 591 1397 558 1387 559 1157 515 1134 535 1134 550.52 1135 551"
      />

      <polygon
        data-floor="5"
        data-building="114H"
        points="1157 515 1243.44 531.54 1344.75 550.92 1387 559 1397 558 1587 591 1587 598 1867 649 1899 654 1913 621 1913 609.67 1913 597.28 1918 586 1918 579.96 1889 575 1892 567 1894 554 1891 553.53 1892 541 1898 529 1899 523 1899 518 1902 513.76 1897.92 513.76 1898 502 1905 489.31 1905 480.36 1908 474 1908 472 1892 468 1902 446.59 1875 444 1877 436 1794 424 1794 407 1781.91 407 1777 414 1776 422 1692 409 1656 450 1638 449 1614 465 1603 480 1524 468 1524 464 1510 463 1510 460 1499 460 1499 454 1236 415 1183 448 1171 448 1136 469 1136 471.07 1158 474 1158 480 1136 494.88 1136 511.73 1157 515"
      />
    </svg>
  )
}

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
}

export default Map
