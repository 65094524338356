import React, { useEffect } from 'react'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-floor="0"
        data-building="114A"
        points="661 363 661 386 692 412 715 412 734 426 766 419 766 405 774 404 774 394 768 390 731 396 710 380 690 382 661 363"
      />
      <polygon
        data-floor="1"
        data-building="114A"
        points="661 363 690 382 710 380 731 396 768 390 764 387 764 363 730 369 708 353 689 355 660 333 661 363"
      />
      <polygon
        data-floor="2"
        data-building="114A"
        points="660 333 689 355 708 353 730 369 764 363 764 342 771 339 771 332 768 329 728 336 706 320 687 323 657 301 659 332 660 333"
      />
      <polygon
        data-floor="3"
        data-building="114A"
        points="657 301 687 323 706 320 728 336 768 329 761 324 761 298 727 303 704 288 686 291 655 270 657 301"
      />
      <polygon
        data-floor="4"
        data-building="114A"
        points="655 270 686 291 704 288 727 303 759.72 298.19 760 274 821 264 827 264 841 273 1000 248 1000 199 944 173 914 176 914 175 912 175 912 176 904 176 904 177 901 177 901 178 886 179 879 176 834 183 834 187 809 190 803 187 733 198 728 195 721 195 650 206 655 270"
      />
      <polygon
        data-floor="0"
        data-building="114"
        points="778 488 778 521 860 583 872 583 872 594 903 618 927 618 948 635 948 649 991 692 1006 688 1033 710 1033 730 1071 762 1097 762 1097 785 1225 894 1649 748 1684 768 1688 768 1694 725 1680 717 1666 721 1646 711 1553 740 1560 743 1560 757 1453 793 1431 781 1405 787 1412 792 1412 807 1363 823 1343 811 1310 821 1325 831 1325 849 1248 875 1098 752 1099 725 1072 732 1033 700 1033 677 1006 655 992 659 947 622 947 607 921 586 903 590 872 565 872 552 860 554 778 488"
      />
      <polygon
        data-floor="6"
        data-building="114"
        points="760 274 760 298 761 298 761 324 772 331 901 419 923 421 947 436 947 453 993 482 1009 479 1037 496 1036 517 1260 672 1340 648 1340 628 1329 621 1360 613 1380 625 1434 612 1433 594 1431 591 1452 586 1474 598 1584 568 1584 551 1674 526 1694 536 1701 534 1732 525 1732 508 1693 491 1693 488 1701 491 1738 482 1738 478 1535 390 1236 458 1179 424 1176 424 1175 422 1146 405 1136 406 1116 394 1102 396 1087 387 1087 373 1076 367 1055 372 1038 376 1034 373 1035 358 1052 355 1066 362 1108 355 1108 343 1123 352 1165 344 1165 335 1246 319 1259 323 1290 318 1290 315 1261 301 1261 266 1159 220 1000 248 841 273 827 264 821 264 760 274"
      />
      <path
        data-floor="2"
        data-building="114"
        d="M1238,443l12,6,33-6V429l-20.82-12-9.18,2-13-8-64,13,43,24Zm447,204-9,3-20-11-92,27,6,3v18l-109,32-21-14-24,7,1,19-48,14-20-11-33,9,16,10-1,18-78,25L1100,680l1-27-27,2-39-27V605l-24-15-18-1-46-30V540l-22-16-23-1-31-21V484l-12,2-93-68.39V445l11,13,82,64,12-4v17l31,19h23l23,17v21l44,33,18-3,24,17v26l39,28,26-6v32l150,120,82-31V790l-21-8,35-10,21,13,47-13V753l-3-3,25-8,21,14,108-34V706l-4-1,90-30,21,10,10-2,27-8V660Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="1"
        data-building="114"
        d="M1265.07,451.39l-4.45-4.32L1250,449l-12-6-19,5,17,10ZM1681,683l-10,2-21-10-90,30,4,1v16l-108,34-21-14-25,8,3,3v19l-47,13-21-13-35,10,21,8v18l-82,31L1099,719V687l-26,6-39-28V639l-24-17-18,3-44-33V571l-23-17H902l-31-19V518l-12,4-82-64,1,30,82,66,12-2v13l31,25,18-4,26,21v15l45,37,14-4,27,22v23l39,32,27-7-1,27,150,123,77-26V831l-15-10,33-10,20,12,49-16V792l-7-5,26-6,22,12,107-36V743l-7-3,93-29,20,10,14-4,21-7V695Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="5"
        data-building="114"
        d="M1076,367l11,6v14l5.07,3,14.93-2V376h2l14,7,40-8v-8l81-17,13,6,33-6V337l-27.21-14.61L1259,323l-13-4-81,16v9l-42,8-15-9v12l-42,7-14-7-17,3-1,15,4,3Zm625,167-7,2-20-10-90,25v17l-110,30-22-12-21,5,2,3,1,18-54,13-20-12-31,8,11,7v20l-80,24L1036,517l1-21-28-17-16,3-46-29V436l-24-15-22-2L772,331v14l-8-3v13l94,66,11-3v18l33,16,23,4,22,12v20l47,29h17l25,16v22l40,26,27-4v26l153,111,77-23,1-22-9-6,32-8,20,11,57-15,1-14-7-3,19-7,23,12,109-33V590l88-26,21,10,7-1,31-9V547Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="3"
        data-building="114"
        d="M1176,424l64-13,13,8,32-7V399l-22.58-12.62L1255,388l-14-8s-77,19-78,19v9l-10,1,22,13Zm514,186-8,2-20-10-93,25,3,2,1,19-108,31-22-12-21,6,5,3-1,18-52,13-21-13-34,8,14,8,1,21-79,22L1102,643V613l-26,8-41-28V570l-24-16-19-2-45-27V506l-22-15-23-3-32-19V451l-12,2-84-57v12l-8-3v13l93,68,12-2v18l31,21,23,1,22,16v19l46,30,18,1,24,15v23l39,27,27-2-1,27,153,116,78-25,1-18-16-10,33-9,20,11,48-14-1-19,24-7,21,14,109-32V669l-6-3,92-27,20,11,9-3,27-8,1-16Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="4"
        data-building="114"
        d="M1116,394l20,12,10-1,7.49,4.39L1163,408v-9l78-19,14,8,32-7V368l-23.81-13.13L1257,356l-13-6-81,17v8l-40,8-14-7h-2v12l-15,2,10,6Zm579,179-7,1-21-10-88,26v16l-109,33-23-12-19,7,7,3-1,14-57,15-20-11-32,8,9,6-1,22-77,23L1103,603V577l-27,4-40-26V533l-25-16H994l-47-29V468l-22-12-23-4-33-16V418l-11,3-94-65.52V387l10,7v2l84,57,12-2v18l32,19,23,3,22,15v19l45,27,19,2,24,16v23l41,28,26-8v30l153,110,79-22V710l-15-8,34-8,21,13,52-13,1-18-5-3,21-6,22,12,108-31-1-19-3-2,93-25,20,10,8-2,29-9,1-15Z"
        transform="translate(0 0)"
      />
    </svg>
  )
}

export default Map
