import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-floor="0"
        data-building="114E"
        points="1009 517 1011.2 517 1016 514 1080 530 1080 535.08 1139 552 1148 547 1154 549 1200 519 1234 526 1232 528 1232 539 1283.96 552 1291 547 1308 550 1436 580 1436 588.67 1484 601 1494 593 1552 609 1552 616.57 1601 629 1610 623 1645 631 1645 645.19 1655 648 1647 659 1624 654 1616 663 1557 645 1552 649 1485 633 1488 627 1444 617 1437 621 1427 617 1429 604 1320 578 1319 584 1231 564 1232 556 1203 548 1150 586 1009 547.22 1009 517"
      />

      <polygon
        data-floor="1"
        data-building="114E"
        points="980 509 1009 517 1011.2 517 1016 514 1080 530 1080 535.08 1139 552 1148 547 1154 549 1200 519 1234 526 1232 528 1232 539 1283.96 552 1291 547 1315.82 551.83 1354.34 560.86 1436 580 1436 588.67 1484 601 1494 593 1552 609 1552 616.57 1601 629 1610 623 1645 631 1651 617 1672 598 1615 584 1607 591 1555 580 1555.79 570 1497 556 1487 564 1439 552 1440 541 1384 530 1284.57 509 1276.11 514 1235 506 1235 491 1202 484 1141.85 514 1081 500 1081 485 1022 473 1009 481.41 1005 481 980 494.17 980 509"
      />

      <polygon
        data-floor="2"
        data-building="114E"
        points="980 474.59 1005 481 1009 481.41 1022 473 1081 485 1081 500 1141.85 514 1202 484 1235 491 1235 506 1276.11 514 1284.57 509 1398.37 532.82 1440 541 1439 552 1487 564 1497 556 1555.79 570 1555 580 1607 591 1615 584 1672 598 1676 595 1654 589 1654 585 1681 556 1621 544 1612 551 1560 540 1561 530 1502 517 1491 524 1442.94 514 1443 503 1294 468 1281 477 1237 469 1237 454 1203 447 1143 477 1082 463 1082 450 1022 439 1009 445.14 1006 445 980 458.29 980 474.59"
      />

      <polygon
        data-floor="3"
        data-building="114E"
        points="980 439 1006 445 1009 445.14 1022 439 1082 450 1082 463 1143 477 1203 447 1237 469 1281 477 1294 468 1352.17 481.66 1443 503 1442.94 514 1491 524 1502 517 1561 530 1560 540 1612 551 1621 544 1681 556 1690 547 1713.16 552 1737 528 1627 504 1617 511 1565 501 1566 491 1505 478 1497 483 1447.03 473 1448 464 1295 434 1285.39 439 1238 431 1239 415 1204 408 1145.34 439 1082 425 1082 412 1022 402 1009 408.36 980 422.97 980 439"
      />

      <polygon
        data-floor="4"
        data-building="114E"
        points="981 403 1009 408.36 1022 402 1082 412 1082 425 1145.34 439 1204 408 1239 415 1238 431 1285.39 439 1295 434 1448 464 1447.03 473 1497 483 1505 478 1566 491 1565 501 1617 511 1627 504 1737 528 1735.25 529.76 1817 547 1845 553 1843 557 1902 570 1904 565 1920 567 1920 530 1823 513 1826 501 1822 499 1815 510 1754 497 1753 488 1631 463 1622 469 1571 459 1571 455 1577 449 1509 438 1499 444 1450.5 434.13 1450.5 431.25 1456 425 1334.95 402 1297 395 1285.41 402 1241 393 1242 377 1207 370 1146 400 1083 387 1084 378 1024 365 1011 371 1008 371 1008 374 980 386.04 981 403"
      />

      <polygon
        data-floor="5"
        data-building="114E"
        points="978.53 365 1008 371 1011 371 1024 365 1084 378 1083 387 1146 400 1207 370 1242 377 1241 393 1285.41 402 1297 395 1391.35 412.72 1456 425 1450.5 431.25 1450.5 434.13 1499 444 1509 438 1577 449 1571 455 1571 459 1622 469 1631 463 1753 488 1754 497 1815 510 1822 499 1826 501 1823 513 1920 530 1920 378 1913 378 1900 393 1765 371 1765.25 365 1744 361 1727 377 1727 394 1724 398 1695 393 1688 397 1650 391 1650 386 1642 384 1642 365 1630 363 1630 352 1617 349 1610 353 1610 356 1579 350 1569 358 1568 367 1559 373 1520 367 1520 360 1469 353 1471 314 1456 310 1450 314.22 1378 302 1368 309 1288 295 1288 289 1241 281 1241 272 1232 271 1222 276 1170 269 1170 264 1160 262 1154 265 1113 258 1053 283 1053 287 1067 288 980 325.01 980 328.07 1012 334 978.53 349.48 978.53 365"
      />

      <polygon
        data-floor="0"
        data-building="114H"
        points="211 517 215 543 258 514 256 495 281 478 292 480 292 470.64 280.43 470.64 211 517"
      />

      <polygon
        data-floor="1"
        data-building="114H"
        points="211 517 280.43 470.64 251.5 467.8 251.5 463.83 276 448 287.32 448 287.32 445.1 351 405 342.65 405 288 439 271 438 205 477 211 517"
      />

      <polygon
        data-floor="3"
        data-building="114H"
        points="200 439 265 402 284 404 338 371 391.83 375.72 480 385 488 379 588 388 588 393.31 640 400 687 367 704 369 729 350 729 335.97 710.72 335.97 711 330 704 335 684 334 639 368 596 365 596 358 565 356 556 360 514 358 514 352 469 347 461 351 334 337 279 368 260 368 195 403 200 439"
      />

      <polygon
        data-floor="4"
        data-building="114H"
        points="195 403 260 368 279 368 334 337 461 351 469 347 514 352 514 358 556 360 565 356 596 358 596 365 639 368 684 334 704 335 711 330 727 316 727 303.42 707 303.42 707 297 700 302 682 299 636 331 593.77 330 593 323 562 321 554 325 512 322 511 316 466 312 458 317 416 315 415 308 330 303 275 333 256 331 191 366 195 403"
      />

      <path
        data-floor="2"
        data-building="114H"
        d="M284,404l-19-2-65,37,5,38,66-39,17,1,54.65-34H351l44-29-57-5Zm445-35.51-18-.82v-4L704,369l-17-2-47,33,48.8,3,24.2-9,16.93-12.18Z"
        transform="translate(0 0)"
      />

      <polygon
        data-floor="5"
        data-building="114H"
        points="191 366 256 331 275 333 330 303 415 308 416 315 458 317 466 312 511 316 512 322 554 325 562 321 593 323 593.77 330 636 331 682 299 700 302 707 297 726 283 726 268.72 707.01 268.72 707 262 724 250 724 246 703.82 246 704 237 569 227 570 224 525 221 515 225 437.93 221 438 216 426 214 419 218 384 217 384 212 215 201 166 222 166 225.4 183 226 183 231 161 229 135 240 135 255.51 113 265 103 265 104 275 173 281 178 322 187 316 191 366"
      />
    </svg>
  )
}

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
}

export default Map
