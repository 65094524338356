import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-building="114B"
        data-floor="0"
        points="209 759 211 780 425 858 427 879 430 880 463 856 463 860 490 870 490 879 544 897 544 903 567 911 567 921 649 950 791 826 790 804 808 810 850 775 850 761 878 737 889 737 937 691 937 677 966 652 986 656 1017 629 1017 608 1036 613 1116 544 1116 515 1037 582 1018 579 1018 595 984 625 966 620 937 643 937 659 890 699 876 695 848 718 849 740 806 774 789 769 790 792 645 913 643 913 623 928 544 896 544 889 540 887 540 884 563 871 535 862 515 875 463 855 463 846 461 846 461 842 470 837 436 827 414 841 367 824 366 812 354 820 306 802 306 789 314 784 219 752 209 759"
      />
      <polygon
        data-building="114B"
        data-floor="6"
        points="129 563 161 573 166 574 189 564 278 591 278 608 327 623 339 618 339 627 390 642 412 628 443 637 439 639 440 657 493 672 515 660 540 665 524 677 525 695 608 720 845 553 844 534 874 515 889 515 937 482 937 469 967 449 988 449 1133 348 1135 299 1070 288 1066 288 1050 299 728 243 621 291 622 328 592 341 592 344 623 350 638 344 717 360 717 371 759 378 774 370 775 381 816 389 832 381 851 384 850 407 831 403 820 410 820 407 799 402 785 410 785 423 781 426 769 423 725 449 716 447 637 493 586 482 586 478 545 469 541 471 530 468 530 465 489 454 481 455 481 452 482 452 482 450 480 444 433 434 424 438 357 422 313 442 298 438 122 515 122 519 158 529 167 525 167 528 128 546 129 563"
      />
      <path
        data-building="114B"
        data-floor="5"
        d="M1122,367V356L988,449H967l-30,20v13l-48,33H874l-30,19,1,19L608,720l-83-25-1-18,16-12-25-5-22,12-53-15-1-18,4-2-31-9-22,14-51-15v-9l-12,5-49-15V591l-89-27-23,10-5-1-25,12,1,17,29,9,7,2,22-10,91,27-3,1v17l50,16,11-6v9l51,15,22-13,33,8-6,4v18l53,16,22-13,26,7-17,11v17l83,26L783,634l-1-23,21,2,41-29V564l29-20,15,2,48-28V506l31-23h20l33-25V447l19,1,92-67V367Zm-528,8c5,0,32,7,32,7l14-6,79,16v10l41,9,14-8,2,1v11l9,2v-7l14-8,21,5v3l11-7,19,4,1-23-19-3-16,8-41-8-1-11-15,8-42-7V360l-79-16-15,6-4.19-.81.19.81-25,11S589,375,594,375Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114B"
        data-floor="4"
        d="M642,407l78,15v12l20.57,5.8L769,423l12,3,4-3v-6l-9-2V404l-2-1-14,7-41-8V392l-79-16-14,6-5-1.26-.84.26L597,393v14l31,7Zm397,41-19-1v11l-33,25H967l-31,23v12l-48,28-15-2-29,20v20l-41,29-21-2,1,23L611,763l-83-26V720l17-11-26-7-22,13-53-16V681l6-4-33-8-22,13-51-15v-9l-11,6-50-16V631l3-1-91-27-22,10-7-2-23,13v16l30,10,8,1,21-11,94,28-6,4v16l48,16,12-7v10l50,16,22-14,32,10-5,4v16l53,17,21-14,26,8-17,12v17l83,24L785,673l-1-27,20,5,41-28V601l30-22,14,1,47-30V535l31-22,20,2,32-22V479l19,2,82-60,11-8V381Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114B"
        data-floor="3"
        d="M645,439l63.25,12.51L716,447l9,2,16-9-21-6V422l-78-15-14,7-6.2-1.4L599,425v14l32,7Zm475-9v-9l-82,60-19-2v14l-32,22-20-2-31,22v15l-47,30-14-1-30,22v22l-41,28-20-5,1,27L615,803l-83-24V762l17-12-26-8-21,14-53-17V723l5-4-32-10-22,14-50-16V697l-12,7-48-16V672l6-4-94-28-21,11-8-1-24,12,1,15,28,10,9,3,21-12,97,28-9,5v17l48,16,11-6v9l50,16,22-15,34,10-8,6v16l52,18,23-17,28,8-22,15v3h2v14l80,25L786,710V683l19,7,41-31-1-23,29-20,15,1,47-30V568l30-22,22,4,31-23V513l19,2,90-70V431Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114B"
        data-floor="1"
        d="M637,493l33-19-24-4-13,7-9.29-2-18.5,11.17,28.29,6.11Zm481,1V484l-79,64-21-2v12l-32,23-19-1-30,20v22l-45,31-15-1-30,24v21l-41,30-19-7,1,28L619,886l-79-25V849l-2-1v-3l19-13-26-10-21,15-52-18V804l8-6-35-11-21,14-49-15-1-10-9,7-49-17-1-16,8-5-95-29-21,11-10-2-20,12v15l35,14,11-7,10-7,95,32-8,5v13l48,18,12-8,1,12,47,17,22-14,34,10-9,5v4h2v9l52,20,20-13,28,9-23,13v3l4,2v7l79,32,20-15h2L790,792l-1-23,17,5,43-34-1-22,28-23,14,4,47-40V643l29-23,18,5,34-30V579l19,3,79-67,10-9V493Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114B"
        data-floor="2"
        d="M646,470l24,3.78,37.68-21.94v-.44l-3.69-.73L645,439l-14,7-9.22-2L601,456v14l32,7Zm392,45-19-2v14l-31,23-22-4-30,22v19l-47,30-15-1-29,20,1,23-41,31-19-7v27L616,846l-80-25V807h-2v-3l22-15-28-8-23,17-52-18V764l8-6-34-10-22,15-50-16v-9l-11,6-48-16V711l9-5-97-28-21,12-9-3-23,13,1,16,2,1,25,8,10,2,21-11,95,29-8,5,1,16,49,17,9-7,1,10,49,15,21-14,35,11-8,6v15l52,18,21-15,26,10-19,13v3l2,1v12l79,25L788,748l-1-28,19,7,41-30V676l30-24,15,1,45-31V600l30-20,19,1,32-23V546l21,2,79-64,10-8V445Z"
        transform="translate(0 0)"
      />
      <polygon
        data-building="114A"
        data-floor="0"
        points="1131 398.27 1131 413 1128 415.18 1189 430 1207 410 1224 414 1256 388 1256 365 1227 388 1208 385 1189 400 1154 394 1147 399 1131 395.89 1131 398.27"
      />
      <polygon
        data-building="114A"
        data-floor="1"
        points="1131 396 1147 399 1154 394 1189 400 1208 385 1227 388 1256 365 1258 363 1258 330 1229 352 1210 350 1188 365 1157 361 1150 367 1122 360.58 1122 367 1131 367 1131 396"
      />
      <polygon
        data-building="114A"
        data-floor="2"
        points="1122 361 1150 367 1157 361 1188 365 1210 350 1229 352 1258 330 1260 328 1262 298 1231 319 1211 318 1190 333 1158 328 1151 334 1133.38 330.99 1133 348 1122 356 1122 361"
      />
      <polygon
        data-building="114A"
        data-floor="3"
        points="902 270 918 273 918 275.71 1050 299 1066 288 1070 288 1135 299 1135 302 1134 303 1133.65 331.03 1151 334 1158 328 1190 333 1211 318 1231 319 1262 298 1263 267 1233 288 1214 285 1190 301 1159 296 1150 300 1102 291 1102 281 1083 278 1075 282 1037 276 1037 266 1018 262 1012 265 1005 264 996 269 959 262 959 251 961 249 945 246 935 251 917 249 902 257 902 270"
      />
      <polygon
        data-building="114A"
        data-floor="4"
        points="890 245 917 249 935 251 945 246 961 249 959 251 959 262 996 269 1005 264 1012 265 1018 262 1037 266 1037 276 1075 282 1083 278 1102 281 1102 291 1150 300 1159 296 1190 301 1214 285 1233 288 1263 267 1264 266 1265 229 1266 228 1267 211 1268 211 1268 207 1195 196 1190 196 1183 201 1036 178 1030 182 1027 182 1027 173 1016 172 1007 177 977 174 972 174 918 200 918 219 891 232 891 244 890 245"
      />
    </svg>
  )
}

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
}

export default Map
