import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-floor="0"
        data-building="114E"
        points="823 484 846 492 868.02 488 902 502 902 505.71 930 517 936.03 517 960 510.62 1001.79 500.5 1005 502 1037 495 1037 520.11 1006 528 994.39 528 934 518 929 519 907 529 823 498.31 823 484"
      />

      <path
        data-floor="1"
        data-building="114E"
        d="M1037,467.31,1008,473l-12-3-39,9,.11,5L934,490l-32-11v-7l-32-13-23,6-24-8.15v8.43L809,468v11l14,5,23,8,22-4,34,14v3.71L930,517h6l65.76-16.5L1005,502l32-7,11-4v-9.79L1037,479ZM1325,381l-12,3,36.5,8.78V387Z"
        transform="translate(0 0)"
      />

      <path
        data-floor="2"
        data-building="114E"
        d="M1037,439.06,1010,444l-13-2-40,8v6l-23,5-32-11v-5l-32-14-21,5-27-7v9l-13,1.87V452l14,4.84L847,465l23-6,32,13v7l32,11,23.11-6-.11-5,39-9,12,3,29-5.69,8-2.31V455l-8-.41ZM1326,355l-17,3v3l-21,5-12-2-19,5,56,15,12-3,24.5,6,1.5-27Z"
        transform="translate(0 0)"
      />

      <path
        data-floor="3"
        data-building="114E"
        d="M1038,410l-28,6-13-3-40,7v7l-22,3-33-9v-7l-33-12-21,6-27-8v11l-13,.13V424l14,5,27,7,21-5,32,14v5l32,11,23-5v-6l40-8,13,2,27-4.94,11-2.31v-8.5l-10-.25Zm289-77-38,8-11-2-41,8v4.5l-24.45,5.13L1257,369l19-5,12,2,21-5v-3l17-3,25,5,.71-22.29Z"
        transform="translate(0 0)"
      />

      <path
        data-floor="4"
        data-building="114E"
        d="M1038,381l-29,6-11-3-41,7v6l-23,4-33-9v-8l-32-10-22,4-26-6v11l-12,.57-1,12.66L821,400l27,8,21-6,33,12v7l33,9,22-3v-7l40-7,13,3,28-6,7-2.64v-10.1l-7-.26Zm291-73-17,2.06V311l-22,5-10-2-48,9,6,.89V326l-25,4-11-2-27,5,7,1.62V336l-25,5-9-3-73,14.87V356l-27,6-9-2v9l7,1.57,19.69-.57.31-2,65-12v-3.48l7.79-1L1145,353l26-5,5,1v-4.37l7-1.63,7,2v6.5l20.79,5.5,26.21-5.5V347l41-8,11,2,38-8,24.71,4.71L1353,312Z"
        transform="translate(0 0)"
      />

      <polygon
        data-floor="5"
        data-building="114E"
        points="806 368 821 372 847 378 869 374 901 384 901 392 934 401 957 397 957 391 998 384 1009 387 1038 381 1046 379 1046 370.57 1039 369 1039 360 1048 362 1075 356 1075 352.87 1084 351 1148 338 1157 341 1182 336 1182 334.62 1175 333 1202 328 1213 330 1238 326 1238 323.89 1232 323 1280 314 1290 316 1312 311 1312 310.06 1329 308 1353 312 1354 275 1356 249 1325 243 1325 242 1312 242 1312 246 1207 263 1207 261 1190 261 1190 266 1176 268 1175 290 1166 290 1166 280 1152 280 1152 288 1148 288 1148 285 1135 283 1114 285 1114 302 1102 304 1088 300 1088 287 1073 285 1071 285 1071 283 1055 283 1054 293 1045 291 1037 290 989 298 989 303 948 310 935 309 935 304 920 304 920 311 893 316 893 313 881 313 881 318 819 328 819 335 806 337.49 806 340.96 821 345 821 353 806 352.88 806 368"
      />

      <polygon
        data-floor="0"
        data-building="114H"
        points="569 829 571 870 483 852 469 859 424.41 849.5 422 824 410.95 819.49 373 804 343 822 308 814 306 792 290 786 251 802 203 793 199 761 226 769 240 764 248 767 285 748 303 755 306 776 340 786 361 778 422 798 422 808 463 820 477 812 517 824 525 822 563 834 569 829"
      />

      <polygon
        data-floor="1"
        data-building="114H"
        points="199 761 226 769 240 764 248 767 285 748 303 755 306 776 340 786 361 778 422 798 422 808 463 820 477 812 517 824 525 822 563 834 569 829 571 818.58 582 813 582 798.35 569 795 569 788 560.68 791 473 766 459 771 349 739 337 742 300 731 298 714 282 710 243 724 241 723 222 731 195 723 196 733 177 740 177 756.02 199 761"
      />

      <polygon
        data-floor="2"
        data-building="114H"
        points="175 718 195 723 222 731 241 723 243 724 282 710 298 714 300 731 337 742 349 739 459 771 473 766 560.68 791 569 788 569 776.55 578.84 772 578.84 756.02 567 753 566 746 557 752 472 725 457 732 402 715 345 700 334 704 296 692 294 675 278 670 238 686 235 685 217 693 190 685 191 696 174 702 175 718"
      />

      <polygon
        data-floor="3"
        data-building="114H"
        points="170 679 190 685 217 693 235 685 238 686 278 670 294 675 296 692 334 704 345 700 402 715 457 732 472 725 557 752 566 746 565 705 556 708 466 686 454 691 410 677 341 660 330 663 291 653 291 638 274 633 236 647 233 646 211 653 186 646 187 657 169 664 170 679"
      />

      <path
        data-floor="4"
        data-building="114H"
        d="M735.23,486.93v-11.3L723,480l-14-2-10.66,3.58V490L672,500l-16-2-23,9.36V516l-30,12-17-4-10,3,49,10,5.42-2L630,519l8-6,17,3v9l36-14,13,2,5-2,20,1,22.76-8.58V490ZM562,663l-9,3-87-19-14,5-44-8v-8l-71-17-11,4-37-10-1-14-19-5-39,14-3-1-19,6-27-7v12.2L163,625l2,16,21,5,25,7,22-7,3,1,38-14,17,5v15l39,10,11-3,69,17,44,14,12-5,90,22,9-3-2-10,11-5V672.06L562,670Z"
        transform="translate(0 0)"
      />

      <polygon
        data-floor="5"
        data-building="114H"
        points="181 606 208 613 227 607 230 608 269 594 288 599 289 613 326 623 337 619 408 636 408 644 452 652 466 647 553 666 562 663 562 645.52 571 641.21 571 628.63 559 626 558 608 569 604 568 585 557 583 557 578 567 574 567 562 625 537 576 527 586 524 603 528 633 516 633 507.36 656 498 672 500 698.34 490 698.34 481.58 709 478 723 480 751 470 751 456.85 734 455 734 442 751 436.89 751 433.7 735 432 735 424 634 407 612 404 519 433 477 445 477 450 466 454.82 459 454 365 487 353 484 174 541 175 553 155 559 156 563 177 568 178 580 158 586 160 603 181 606"
      />
    </svg>
  )
}

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
}

export default Map
