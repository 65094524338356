import React, { useEffect } from 'react'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        data-floor="0"
        data-building="114B"
        d="M1318,578v-4l7-2-35-19,4-8-49-24-15,5v15l-91,31-16-8-77,27,1,17-36,8-50-29,1-16-35-21-18,6-12-6v13l119,69,243-93,29,13v5l5,1v14l41,23,11-5Zm349,264-27-13-84,47,13,6v12l-46,24-76-43V856l7-3-80-45-4,3-30-18v15l32,17-1,19,146,84,155-86-1-2Zm73-69,16,6-3,5,8,4,26-14-26-10Zm104-36h-4l-16-8-10,5,13,6-1,3-2,1v11l12,9,30-16Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="1"
        data-building="114B"
        d="M1325,572l9-3,1-25-40-19-25-11,1-14-26-11-13,6v13l-93,30-15-7-77,28v14l-35,10-50-26V540l-36-21-18,6-25-14-19,6v15l33,18,12,6,18-6,35,21-1,16,50,29,36-8-1-17,77-27,16,8,91-31V526l15-5,49,24-4,8Zm574,124-25,13-42-19-13,5,15,7,1,17-40,17-26-13-24,11,15,8,1,13-88,48-27-14-87,47,14,6v15l-41,20-82-44V817l6-2-130-71-22,11v16l36,22,30,18,4-3,80,45-7,3v19l76,43,46-24V882l-13-6,84-47,27,13,87-49-1-9,3-5-16-6,21-9,26,10,37-19V744l2-1,1-3-13-6,10-5,16,8h4l22,11,50-29,1-15Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="2"
        data-building="114B"
        d="M1335,544l3-34-48-19,1-16-43-18-14,3v16l-93,28-17-7-76,27v14l-35,11-52-27V508l13-4-47-19-19,6-24-12-21,7v13l20,12,25,14,18-6,36,21v17l50,26,35-10V559l77-28,15,7,93-30V495l13-6,26,11-1,14Zm547,127-44-16-7,6,12,4-1,16-40,17-27-12-20,9,13,7v14l-88,46-28-12-85,46,12,6v14l-41,20-83-45,1-17-125-67-25,10v17l20,10,130,71-6,2v16l82,44,41-20V842l-14-6,87-47,27,14,88-48-1-13-15-8,24-11,26,13,40-17-1-17-15-7,13-5,42,19,46-24V653Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="3"
        data-building="114B"
        d="M1338,510l26,10v-2h3V503l-6-3,1-14-68-29,1-14-46-19-12,2v18l-41,10-59,15-11-5-78,25v15l-35,9-51-27V473l13-4-49-17-19,5-22-10-22,6v15l21,11,24,12,19-6,47,19-13,4v14l52,27,35-11V524l76-27,17,7,93-28V460l14-3,43,18-1,16Zm552,123-42-17-10,6,11,4v13l-40,19-26-10-18,9,12,4v14l-92,47-26-12-89,43,12,7v17l-38,17-84-43,1-17-125-66-26,11,21,28,125,67-1,17,83,45,41-20V802l-12-6,85-46,28,12,88-46V702l-13-7,20-9,27,12,40-17,1-16-12-4,7-6,44,16,38-18V618Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="4"
        data-building="114B"
        d="M1362,486l-1,14,6,3,17,8,43-16-6-1v-7l17-6-13-5-17,5-44-17V452l-67-29,1-13-46-19-14,2v16l-93,29-18-8-78,25v13l-36,9-51-25V439l-37-20-18,5-24-11-21,5v17l23,12,22,10,19-5,49,17-13,4v13l51,27,35-9V489l78-25,11,5,100-25V426l12-2,46,19-1,14Zm537,108-44-18-10,6,14,6-1,14-40,18-27-11-20,8,14,7-1,14-92,43-27-11-88,41,11,5-1,19-39,16-85-42,1-18-124-62-27,9v17l23,13,125,66-1,17,84,43,38-17V760l-12-7,89-43,26,12,92-47V661l-12-4,18-9,26,10,40-19V626l-11-4,10-6,42,17,30-15V585Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="5"
        data-building="114B"
        d="M1364,452v12l44,17,17-5,13,5h3l24-9,9,3,22-8-68-27-17,6-43-16v-3l16-4-84-34v-2l6-2v-7l-52-21-14,3v16l-97,27-15-7-93,27,14,7v2l-35,11-51-24v-3l18-6-56-26-18,5-25-10-21,4v18l22,12,24,11,18-5,37,20v13l51,25,36-9V455l78-25,18,8,93-29V393l14-2,46,19-1,13Zm544,103-45-16-8,4,9,3v16l-38,18-27-10-18,7,11,4v14l-92,44-28-11-88,39,13,5v14l-42,21-84-40,1-17,5-3-85-44-9,3-38-16-11,5-20,5v16l150,75-1,18,85,42,39-16,1-19-11-5,88-41,27,11,92-43,1-14-14-7,20-8,27,11,40-18,1-14-14-6,10-6,44,18,21-9V550Z"
        transform="translate(0 0)"
      />
      <polygon
        data-floor="6"
        data-building="114B"
        points="882 379 907 389 925 384 981 410 963 416 963 419 1014 443 1049 432 1049 430 1035 423 1128 396 1143 403 1240 376 1240 360 1254 357 1306 378 1306 385 1300 387 1300 389 1384 423 1368 427 1368 430 1411 446 1428 440 1494.81 466.53 1474 475 1465 472 1441 481 1438 481 1421 487 1421 494 1427 495 1384 511 1368 506 1367 518 1364 518 1364 520 1338 511.98 1335 544 1362 555 1361 566 1354 561 1334 569 1318 574 1318 578 1345 590 1383 606 1392 603 1477 647 1472 650 1471 667 1555 707 1597 686 1597 672 1584 667 1672 628 1700 639 1792 595 1792 581 1781 577 1799 570 1826 580 1864 562 1864 546 1855 543 1863 539 1908 555 1920 550 1920 456 1644 363 1608 375 1607 366 1597 363 1585 367 1585 385 1572 389 1564 388 1564 379 1496 356 1485 359 1481 358 1481 342 1466 336 1449 342 1434 336 1438 294 1219 220 1167 232 1167 248 861 326 861 329 888 343 860 348 860 367 882 379"
      />
      <polygon
        data-floor="0"
        data-building="114A"
        points="638 379 639 403 707 442 763 427 780 434 865.8 403.62 861 401 861 383 844 377 825 382 829 384 829 397 790 405 781 401 774 401 762 395 704 409 700 409 680 416 650 398 651 386 638 379"
      />
      <polygon
        data-floor="1"
        data-building="114A"
        points="638 379 651 386 650 398 680 416 700 409 704 409 762 395 774 401 781 401 790 405 829 397 829 384 825 382 844 377 861 383 882 379 860 367 860 350.77 852 354 844 349 825 354 825 364 788 372 778 368 772 368 760 362 699 377 682 381 651 366 651 355 636 347 638 379"
      />
      <polygon
        data-floor="2"
        data-building="114A"
        points="636 347 651 355 651 366 682 381 760 362 772 368 778 368 788 372 825 364 825 354 844 349 852 354 860 351 860 348 888 343 861 329 861 326 929.12 308.64 918 304 899 308 899 310 862 320 858 319 851 321 842 316 825 321 826 330 788 339 781 338 772 340 759 334 698 349 692 345 680 347 649 333 648 323 634 315 636 347"
      />
      <polygon
        data-floor="3"
        data-building="114A"
        points="634 315 648 323 649 333 680 347 692 345 698 349 759 334 772 340 782 338 788 339 826 330 825 321 842 316 851 321 858 319 862 320 899 310 899 308 918 304 929 309 994.29 292.02 993 259 933 276 919 271 862 288 857 286 850 287 842 284 823 289 823 296 786 305 780 304 771 306 758 300 689 320 647 299 647 289 632 282 634 315"
      />
      <polygon
        data-floor="4"
        data-building="114A"
        points="632 282 647 289 647 299 689 320 758 300 771 306 780 304 786 305 823 296 823 289 842 284 850 287 857 286 862 288 919 271 933 276 994 259 994 209 976 203 976 193 930 177 925 177 914 180 883 168 815 182 815 185 804 186 804 190 716 209 700 212 690 212 690 216 675 219 676 223 628 233 632 282"
      />
    </svg>
  )
}

export default Map
