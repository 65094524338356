import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-floor="0"
        data-building="114E"
        points="449 813 466 820 480.31 813 508 823 508 834.85 570 857 607 839 607 829.83 876 703 886 706 932 684 931 676 939 672 942.35 672 1021 635 1021 621.48 1049 608 1060 610 1123 581 1149 569 1149 591.97 1124 604 1061 636 1050 633 1020 646 1020 661 931 703 931 711 890 732 878 729 575 878 451 833 449 813"
      />

      <polygon
        data-floor="1"
        data-building="114E"
        points="432 808 449 813 466 820 480.31 813 508 823 508 834.85 570 857 607 839 607 829.83 783.28 746.72 876 703 886 706 932 684 931 676 939 672 942.35 672 1021 635 1021 621.48 1049 608 1060 610 1149 569 1150 568 1150 539.45 1060 580 1049 579 931 636 931 647 884 666 874 664 604 787 604 800 569 816 549 806 504 793 504 780 484 772 463 779 429 767 417 771 417 787.1 431 793 432 808"
      />

      <polygon
        data-floor="2"
        data-building="114E"
        points="1061 545 1050 544 930 598 931 613 885 632 875 630 602 747 603 760 566 775 502 754 502 741 481 733 463 741 445 736 427 730 426.5 724.44 423.5 724.44 413 731 414 748 429 751.33 429 767 463 779 484 772 504 780 504 793 549 806 569 816 604 800 604 787 874 664 884 666 931 647 931 636 1049 579 1060 580 1150 539.45 1151 539 1152 507 1061 545"
      />

      <polygon
        data-floor="3"
        data-building="114E"
        points="1061 518 1050 516 930 567 931 578 885 597 874 595 600 708 601 720 564 734 498 713 499 701 479 694 457 700 441 696 424 691 419 689 411 693 411 708.23 427 711.24 427 730 445 736 463 741 481 733 502 741 502 754 566 775 603 760 602 747 875 630 885 632 931 613 930 598 1050 544 1061 545 1152 507 1152 480.81 1061 518"
      />

      <polygon
        data-floor="4"
        data-building="114E"
        points="1063 486 1050 485 929.43 533 930.19 539.45 885 557 875 554 598 666 598 678 559 692 496 673 496.04 661.5 475 655 456 660 439 656 421 650.15 408 652.79 408 669 424 673.17 424 691 441 696 457 700 479 694 499 701 498 713 564 734 601 720 600 708 874 595 885 597 931 578 930 567 1050 516 1061 518 1152 480.81 1153 450 1063 486"
      />

      <polygon
        data-floor="5"
        data-building="114E"
        points="405 630 421 635 421 650.15 439 656 456 660 475 655 496.04 661.5 496 673 559 692 598 678 598 666 875 554 885 557 930.19 539.45 929.43 533 1050 485 1063 486 1153 450 1154 411 1156 410 1156 406.69 1112 400 1102 403 1097 402 1097 401 999 386 987.64 386 914 410 914 413 901 415 901 418 915 420 915 431 900 435 900 439 887 443 887 452 870 458 869 490 845 500 837 498 837 490 818 486 807 491 808 504 786 512 773 510 764 514 763.51 507 750 503 736 508 736 518 704 511 669 522 656 519 573 547 541 537 512 549 510 535 500.9 533 490 537 492 554 422 575 422 584 404 590 404 593.98 414 597 414 608 404 614.01 405 630"
      />

      <polygon
        data-floor="0"
        data-building="114H"
        points="1152.27 472.5 1195 476 1222 440 1222 429.2 1232 414 1241.43 414 1261 387 1261 401.75 1241 434 1235 434 1224 447 1224 451 1214 465 1214 487 1195 501 1152 499.34 1152.27 472.5"
      />

      <path
        data-floor="1"
        data-building="114H"
        d="M1242,392h-9l-44,56-35.91-1.54-.82,26L1195,476l27-36V429.2l10-15.2h9.43L1261,387l1-21Zm-185-10-69.36-5.14V386H999l64,9.8V383l-6,.1Z"
        transform="translate(0 0)"
      />

      <path
        data-floor="2"
        data-building="114H"
        d="M1242,367l-8,1-45.72,53-34.51-1-.68,26.5L1189,448l44-56h9l20-26V341.76Zm-254.36,9.86L1057,382V357l-69.36-1.65Z"
        transform="translate(0 0)"
      />

      <path
        data-floor="3"
        data-building="114H"
        d="M1243,346h-9l-41,52-49-2v-2.16l-65-2.06v6.46l18,2.76v1l5,1,10-3,44,6.69V410l-2,1-.23,9,34.51,1L1234,368l8-1,20-25.24,1-3.76,1-17Zm-255.36,9.35L1057,357l1-23-70.36-1.8Z"
        transform="translate(0 0)"
      />

      <path
        data-floor="4"
        data-building="114H"
        d="M1057.89,336.44h6.93V334l7.18-6h8.25l5.75-5-7,.45V322l8-7h-29v-3.12L987.64,310v22.2l70.25,1.8ZM1244,322l-9,1-42,49-48-2v-6l-66-.65v-1.26l-22-2.18V383.1l6-.1v3l16,.33v5.45l65,2.06V396l49,2,41-52h9l21-25V300.49Z"
        transform="translate(0 0)"
      />

      <polygon
        data-floor="5"
        data-building="114H"
        points="1057 359.91 1078.99 362.09 1078.99 363.35 1145 364 1145 370 1193 372 1235 323 1244 322 1264 300.49 1266 266 1226 265 1222 269 1212 269 1212 263 1202.01 263 1202 268 1189 268 1189 264 1180 264 1180 261 1171.5 260.55 1171.5 262.57 1152 263 1152 265 1130 265 1130 261 1114.03 260.55 1114 264 1106 264 1106 260 1066.19 260 1066 257 1049.5 257 1049.5 262 1021 262 1021 258 1002 258 1002 268 988 279 987.64 310 1058 311.88 1057.89 315 1087 315 1079.45 321 1078.99 323.45 1086 323 1080.25 328 1072 328 1064.82 334 1064.82 336.44 1057.89 336.44 1057 359.91"
      />
    </svg>
  )
}

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
}

export default Map
