import React, { useEffect } from 'react'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        data-building="114C"
        data-floor="0"
        d="M992,668L758.2,533l-27.2,8l-32-17.7V539l41,23l12-4l218,131L992,668z M1140,1011l-194-67v36.7l14,5.3l4-5
					h2l68,24l1,2l-4,6l108,35l28-39l-20-6L1140,1011z M1216,962l-6-1l-19,29l24,8l33-46l-20-7L1216,962z M1362,723l-25,35l-7-1l-4,4
					l15,4l2,12l22-29l1-24L1362,723z M1270,895l0.9,1h3.1v-8l-4,5.1V895z"
      />
      <polygon
        data-building="114C"
        data-floor="7"
        points="685,295 720,308 751,301 1005,411 1016,411 1114,453.9 1126,442 1175,426 1185,417 1197,418 1216,411 
		1216,401.2 1224.9,397.5 1233,399 1233,397.3 1236,397 1236,404 1261,393 1222,377 1224,331 1182,315 1182,301 1100,271 
		1094.5,271 1078,277 1071,274 1071,269 1060,266 1055,268 1002,248 1002,235 883,191 876.7,191 863,196 858,196 852,193 852,188 
		843,184 838,186 824,182 819.8,182 769,195 769,204 746,209 742,208 736,208 680,222 681,240 669,244 669,246.6 683,253 683,269 
		671,273 671,289"
      />
      <path
        data-building="114C"
        data-floor="1"
        d="M1392,685l-2,2l-26-5l-15,12l-17.8,23.3l14.8,5.7l-1,15l-15.5,18.2l0.5,0.8l7,1l14.8-20.8L1362,723l24,6
					l17-24l1-15L1392,685z M1292,822l-19.8,26.4l15.8,4.9l22-31.4l-18-2.4V822z M1029,634l-13-7l-14,1L760,495l-32,8l-31-16v11.9
					l-12,2.1v14.1l14,8.1l32,17.7l27.2-8L992,668L1029,634z M1270.9,896l-0.9-1l-10,12l-32-9l-50,65l-31.9-10l-3.1,3l-197-60v48
					l194,67l7-8l20,6l8.8,2l15.2-21l19-29l6,1l12-17l20,7l8,2l27-37v-17.5l-9-3.5H1270.9z"
      />
      <path
        data-building="114C"
        data-floor="2"
        d="M1326,761l-15,17l-16-3l-20.2,25.4l17.2,4.3v14.8l18,2.4l26-36l-1-12l6-9L1326,761z M1395.8,643l-0.8,1
					l-28-4.8l-22,27.8v9l5,1l-1,17l15-12l26,5l2-2l15-20l1-16L1395.8,643z M1265,858l-32-8l-52.5,63.5L1148,905l-4,3l-199-56l1,44
					l197,60l3.1-3l31.9,10l50-65l32,9l10-12v-1.9l4-5.1l14-19v-15.6l-15.8-4.9L1265,858z M1016,627l13,7l13-12h4l12,3l12-11l-57-29
					l-10,1L759,457l-34,9l-31-16v11.6l-11,3.4v12.8l14,9.2l31,16l32-8l242,133L1016,627z"
      />
      <path
        data-building="114C"
        data-floor="3"
        d="M1399,601l-27-5-24,27v7l5,2v16l-19.68,24.28L1345,676v-9l22-27.8,28,4.8,17-22,1-16ZM1268,809l-33-7-51,61-33-8-3,1L945,796.29l-1,.71,1,55,199,56,4-3,32.5,8.51L1233,850l32,8,27-36V804.76l-17.19-4.33Zm29-47-2,.21V775l16,3,34-40,1-16.67-14.79-4.06ZM1013,585l57,29,27-25-82-42h-12L757,419l-33,9-32-16v11.71L680,428l1,15,13,7,31,16,34-9,244,129Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114C"
        data-floor="4"
        d="M1404,557l-27-5l-23,23l-9-4l-51,56h-8.5l-36.5,42v9l-21-5v-34l-11-3l-7,7l-1,23l-16-4v-16l5-2v-22l-20-5
					l-9,8v31l-98-25l-1-17l-12,11l-16-3l-72,67l-9,9v17l-17,18v63l204,60l3-1l33,8l51-61l33,7l27-34v-18l-9-2v-13l11-15l0.5-4
					l-4.5-1l60-74v-16l-5-2v-7l24-27l27,5l17-21l1-17L1404,557z M1015,547l82,42l9-10l2-34l-90.1-44.5L1003,501L755,380l-32,9
					l-33-15v12.2l-12,3.8l1,16l13,6l32,16l33-9l246,128H1015z"
      />
      <polygon
        data-building="114C"
        data-floor="5"
        points="1410,512 1381,507 1357,532 1348.3,533 1295,587 1269,583 1258,593 1194,579 1194,565 1196,562 
					1108.1,542 1108.1,542 1110,500 1015,456 1004,456 752,341 720,350 688,335 688,347.7 676,352 676,367 690,374 723,389 755,380 
					1003,501 1017.9,500.5 1108,545 1106,579 1070,614 1071,631 1119,642 1121,641 1142,647 1142,650 1169,656 1169,625 1178,617 
					1198,622 1198,644 1193,646 1193,662 1209,666 1210,649 1210,643 1217,636 1228,639 1228,673 1249,678 1249,669 1285.5,627 
					1294,627 1345,571 1354,575 1377,552 1404,557 1421,537 1422,519			"
      />
      <polygon
        data-building="114C"
        data-floor="6"
        points="1430,448 1430,445.7 1397,431 1397,429 1299,391 1279,400 1261,393 1236,404 1236,397 1233,397.3 
					1233,399 1224.9,397.5 1216,401.2 1216,411 1197,418 1185,417 1175,426 1126,442 1114,453.9 1016,411 1005,411 751,301 720,308 
					685,295 685,309.4 673,313 673,328.8 688,335 720,350 752,341 1004,456 1015,456 1110,500 1108.1,542 1196,562 1194,565 
					1194,579 1258,593 1269,583 1295,587 1348.3,533 1357,532 1381,507 1410,512 1426,491 1426,474.6 1400,464 1414,466			"
      />
      <polygon
        data-building="114D"
        data-floor="0"
        points="519,388 521,416 550,434 550,441.2 592,464 600,461 644,486 683,474.9 683,465 694,461.6 694,450 
		686,445.7 644,460 600,435 589.7,437 550,414 550,406"
      />
      <polygon
        data-building="114D"
        data-floor="1"
        points="519,388 550,406 550,414 589.7,437 600,435 644,460 686,445.7 681,443 680,428 692,423.7 692,415.3 
		640,420 595.3,397.5 587,399 547,379 547,370 515,354"
      />
      <polygon
        data-building="114D"
        data-floor="2"
        points="515,354 547,370 547,379 587,399 595.3,397.5 640,420 692,415.3 692,412 679,406 678,390 690,386.2 
		690,378.2 638,381 593,360 583,361 544,344 543,337 512,320"
      />
      <polygon
        data-building="114D"
        data-floor="3"
        points="512,320 543,337 544,344 583,361 593,360 638,381 690,378.2 690,374 676,367 676,352 688,347.7 
		688,340.9 636,346 591,325 581,326 541,310 541,302 509,284"
      />
      <polygon
        data-building="114D"
        data-floor="4"
        points="509,284 541,302 541,310 581,326 591,325 636,346 688,340.9 688,335 673,328.8 673,313 685,309.4 
		685,300.5 632,306 588,292 579,292 537,277 537,267 506,252"
      />
      <polygon
        data-building="114D"
        data-floor="5"
        points="506,252 537,267 537,277 579,292 588,292 632,306 685,300.5 685,295 671,289 671,273 683,269 
		683,257.8 629,264 584,248 575,252 534,237 533,227 501,213"
      />
      <polygon
        data-building="114D"
        data-floor="6"
        points="501,213 533,227 534,237 575,252 584,248 629,264 683,257.8 683,253 669,246.6 669,244 681,240 
		680,222 627,230 582.8,213 572,216 531,203 530,192 498,181"
      />
      <polygon
        data-building="114D"
        data-floor="7"
        points="498,181 530,192 531,203 572,216 582.8,213 627,230 680,222 736,208 742,208 746,209 750.3,208.1 
		750.3,202 768,197 768,183.6 753,179 752,168 766,164 765,161 728,147 728,136 671,114 656,118 648,118 615,105 572,113 572,101 
		568,99 558,100 558,116 493,129"
      />
    </svg>
  )
}

export default Map
