import styled from 'styled-components'
import media from 'styles/media'

export const ButtonContainer = styled.div`
  position: relative;
  min-width: fit-content;
  flex: 1 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SelectContainer = styled.div`
  min-width: 200px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  flex: 1;
`

export const InputRangeContainer = styled.div`
  min-width: 200px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  flex: 1;
`

export const InputContainer = styled.div`
  display: flex;
  gap: 16px;
  flex: 2 1;

  ${media.md.max} {
    flex-wrap: wrap;
  }
`

export const InputRangeLable = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  line-height: 18px;
`

export const FindFlatWraper = styled.div`
  width: 100%;
  height: 0px;

  margin: 0px auto;
  box-sizing: border-box;

  position: absolute;
  top: -64px;

  ${media.lg.max} {
    position: initial;
    height: fit-content;
    margin-bottom: 40px;
  }
  ${media.sm.max} {
    width: calc(100% - 32px);

    margin-bottom: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }
`

export const FindFlatContainer = styled.div`
  width: 100%;

  padding: 48px 64px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 32px;

  position: absolute;
  bottom: 0px;

  background-color: white;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.15);

  ${media.lg.max} {
    position: initial;
  }
  ${media.sm.max} {
    padding: 32px 16px;
  }
`

export const StyledForm = styled.form`
  margin: 0px;

  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  & select {
    min-width: 181px;
    height: 46px;

    padding: 12px 16px;

    flex: 0 1;

    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray05};
    border-radius: 16px;
  }

  ${media.sm.max} {
    flex-direction: column;
  }
`

export const InputRangeWraper = styled.div`
  min-width: fit-content;

  padding: 15px 24px;

  flex: 0 1;

  border: 1px solid ${({ theme }) => theme.colors.gray05};
  border-radius: 32px;

  & .input-range {
    position: relative;
  }

  & .input-range__label--max,
  .input-range__label--min,
  .input-range__label-container {
    opacity: 0;
    transform: scale(0);
    user-select: none;
  }

  & .input-range__track,
  .input-range__slider-container {
    transition: none !important;
  }
  .input-range__slider {
    transform: scale(1);

    &:active {
      transform: scale(1.1);
    }
  }

  .input-range__slider-container {
    bottom: 0px;
  }

  & .input-range__slider-container:active .input-range__label-container {
    display: block;
    padding: 4px 8px;

    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    font-family: 'Kumbh Sans Variable', sans-serif;

    color: white;
    background-color: black;
    border-radius: 8px;

    position: absolute;
    top: -10px;
    left: -20px;

    transform: scale(1);
    opacity: 1;

    transition: all 0.3s ease;

    &::after {
      content: ' m²';
    }
  }

  & .input-range__track--active,
  .input-range__slider {
    background: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.white};
  }
`

export const ResetFiltersButton = styled.button`
  background: transparent;
  border: none;
  width: fit-content;
  margin: 24px auto 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  ${media.lg.min} {
    position: absolute;
    top: 61px;

    svg path {
      transition: 0.3s;
    }

    &:hover {
      svg path {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`
