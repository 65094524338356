import React, { useEffect } from 'react'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-building="114C"
        data-floor="0"
        points="316 673 316 684.17 378 724 405 712 601 845 728 805 728 788.02 876 747 890 762 1007 727 1007 709.88 1131 676 1144 689 1324 637 1324 619.91 1364 610 1364 590.86 1325 600 1325 609 1306 614 1306 624 1201 653 1191 645 1146 658 1141 653 1006 688 1006 697 973 707 973.93 717.93 916 732 907 724 889 729 885 723 726 767 726 776 668 791 669 804 586 825 550 802 549 786 555 783 534 770 534 761 502 741 481 750 426 720 425 713 381 688 374 691 336 665 316 673"
      />
      <polygon
        data-building="114C"
        data-floor="1"
        points="282 654 316 673 336 665 374 691 381 688 425 713 426 720 481 750 502 741 534 761 534 770 555 783 549 786 550 802 586 825 669 804 668 791 726 776 726 767 885 723 889 729 907 724 916 732 973.93 717.93 973 707 1006 697 1006 688 1141 653 1146 658 1191 645 1201 653 1306 624 1306 614 1325 609 1325 600 1364 590.86 1384 584 1384 569.24 1369 559 1369 550 1328 561 1328 569 1308 572 1308 584 1203 612 1189 601 1147 611 1145 610 1007 646 1007 655 973 662 973 674 915 689 902 675 722 722 722 730 664 744 664 759 582 779 545 758 545 748 544 741 555 736 497 698 475 707 368 641 332 622 308 632 299.92 627.06 272 610 272 594.93 285 589 272 580.7 250.8 590.86 252 606 286 632 279 637 282 654"
      />
      <polygon
        data-building="114C"
        data-floor="2"
        points="245 565 272 580.7 285 589 272 594.93 272 610 308 632 332 622 368 641 475 707 497 698 555 736 544 741 545 748 545 758 582 779 664 759 664 744 722 730 722 722 902 675 915 689 973 674 973 662 1007 655 1007 646 1145 610 1147 611 1189 601 1203 612 1308 584 1308 572 1328 569 1328 561 1369 550 1388 545 1388 528.77 1373 518 1373 509 1333 518 1332 528 1312 531 1312 541 1206 567 1192 558 1149 568 1149 566 1083 582 1075 577 1075 585 1007 602 1007 610 973 617 973 629 915 643 902 630 719 677 719 682 661 696 661 713 577 733 541 714 540 695 559 685 496 651 470 661 326 578 300 588 264 564 263 551 278 546 266 540 242 549 245 565"
      />
      <polygon
        data-building="114C"
        data-floor="3"
        points="235 523 266 540 278 546 263 551 264 564 300 588 326 578 470 661 496 651 559 685 540 695 541 714 577 733 661 713 661 696 719 682 719 677 902 630 915 643 973 629 973 617 1007 610 1007 602 1075 585 1075 577 1083 582 1149 566 1149 568 1189.39 558.61 1192 558 1206 567 1312 541 1312 531 1332 528 1333 518 1373 509 1392 504 1392 488.05 1378 478 1378 467 1337 476 1337 481 1317 484 1317 499 1210 524 1195 515 1151 524 1085 540 1076.57 533 1076 541 1008 558 1008 562 973 570 973 584 914 599 901 586 716 632 716 637 657 654 657 665 573 685 534 663 534 646 550 639 489 606 464 615 318 534 293 544 255 519 256 507 269 503 258 498 235 507.55 235 523"
      />
      <polygon
        data-building="114C"
        data-floor="4"
        points="229 481 258 498 269 503 256 507 255 519 293 544 318 534 464 615 489 606 550 639 534 646 534 663 573 685 657 665 657 654 716 637 716 632 901 586 914 599 973 584 973 570 1008 562 1008 558 1076 541 1076.57 533 1085 540 1151 524 1195 515 1210 524 1317 499 1317 484 1337 481 1337 476 1378 467 1397 463 1397 446.25 1382 435 1382 424 1341 433 1341 437 1320 441 1320 455 1211 480 1197 471 1154 480 1153 479 1086 493 1078 489 1078 497 1008 513 1008 520 973 529 973 539 913 552 900 540 713 583 713 587 653 602 653 617 566 636 529 616 528 598 548 589 485 558 458 569 348.01 509 348 505 374 495 373 488 376 484 376 475 384 473 384 452 394 447 404 450 404 464 416 461 410 411 471 390 471 385 442 372 431 376 400 362 346 381 340 377 327 381 327 388 244 417 245 426 222 435 245 451 228 458 229 481"
      />
      <polygon
        data-building="114C"
        data-floor="5"
        points="416 461 404 464 404 450 394 447 384 452 384 473 376 475 376 484 373 488 374 495 348 505 348.01 509 458 569 485 558 548 589 528 598 529 616 566 636 653 617 653 602 713 587 713 583 900 540 913 552 973 539 973 529 1008 520 1008 513 1078 497 1078 489 1086 493 1153 479 1154 480 1197 471 1211 480 1320 455 1320 441 1341 437 1341 433 1382 424 1401 420 1402 402 1386 392 1386 381 1325 396 1325 410 1215 434 1200 425 1156 435 1153 434 1087 448 1079 443 1079 452 973 476 974 493 913 504 899 492 710 534 710 540 649 554 649 567 563 586 524 567 523 549 545 539 419 475 416 461"
      />
      <polygon
        data-building="114C"
        data-floor="6"
        points="471 390 410 411 416 461 419 475 545 539 523 549 524 567 563 586 649 567 649 554 710 540 710 534 860.71 500.51 899 492 913 504 974 493 973 476 1079 452 1079 443 1087 448 1153 434 1156 435 1200 425 1215 434 1325 410 1325 396 1386 381 1406 377 1407 359 1391 350 1392 335 1329 349 1329 364 1218 388 1202 378 1159 389 1156 388 1089 401 1080 397 1080 404 1010 419 974 429 974 443 912 455 898 445 882 448 707 484 707 466 696 454 720 449 716 445 703 446 683 422 682 404 677 399 659 401 659 386 650 383 650 373 649 367 645 367 626 359 608 365 574 350 509 374 508 366 496 365 469 374 471 385 471 390"
      />
      <polygon
        data-building="114C"
        data-floor="7"
        points="649 367 650 373 650 383 659 386 659 401 677 399 682 404 683 422 703 446 716 445 720 449 696 454 707 466 707 484 898 445 912 455 974 443 974 429 1010 419 1080 404 1080 397 1089 401 1156 388 1159 389 1202 378 1218 388 1329 364 1329 349 1392 335 1410 331 1411 313 1396 305 1397 288.18 1415 284 1415 280.38 1399 271 1400 256 1352 231 1328 235 1306 223 1306 216 1262 193 1190 204 1190 207 1156 215 1156 219 797 284 797 289 699 309 701 357 649 367"
      />
      <polygon
        data-building="114D"
        data-floor="0"
        points="1392 488.05 1392 504 1373 509 1474 578 1538 560 1555 572 1648 546 1650 528 1691 518 1694 498 1654 508 1653 524 1617.33 533.03 1600 524 1560.71 533.03 1497 550 1484 545 1476 546 1392 488.05"
      />
      <polygon
        data-building="114D"
        data-floor="1"
        points="1378 478 1476 546 1484 545 1497 550 1560.71 533.03 1600 524 1617.33 533.03 1653 524 1654 508 1694 498 1712 493 1713 480 1700 470 1702 459 1664 467 1669 470 1668 484 1624 494 1608 486 1503 509 1487 500 1484 501 1397 446.25 1397 463 1378 467 1378 478"
      />
      <polygon
        data-building="114D"
        data-floor="2"
        points="1382 435 1397 446.25 1484 501 1487 500 1503 509 1608 486 1624 494 1668 484 1669 470 1664 467 1702 459 1719 455 1721 440 1707 431 1709 419 1672 427 1676 429 1675 443 1631 454 1613.6 446.25 1574 456 1573 454 1509 468 1402 402 1401 420 1382 424 1382 435"
      />
      <polygon
        data-building="114D"
        data-floor="3"
        points="1386 392 1509 468 1573 454 1574 456 1613.6 446.25 1631 454 1675 443 1676 429 1672 427 1709 419 1727 415 1729 400 1715.03 392 1717 378 1679 386 1684 388 1683.11 402 1638 412 1620 404 1579 413 1515 427 1407 359 1406 377 1386 381 1386 392"
      />
      <polygon
        data-building="114D"
        data-floor="4"
        points="1391 350 1515 427 1620 404 1638 412 1683.11 402 1684 388 1679 386 1717 378 1735 374 1736.22 359 1723 351 1724 337 1691 345 1690.05 359 1645 369 1627 362 1520 384 1410.53 321.53 1410 331 1392 335 1391 350"
      />
      <polygon
        data-building="114D"
        data-floor="5"
        points="1410.53 321.53 1520 384 1627 362 1645 369 1690.05 359 1691 345 1724 337 1743 333 1745 317 1729 309.85 1731 295 1699 302 1698 318 1653 327 1635 320 1527 341 1415 284 1397 288.18 1396 305 1411 313 1410.53 321.53"
      />
      <polygon
        data-building="114D"
        data-floor="6"
        points="1415 284 1527 341 1635 320 1653 327 1698 318 1699 302 1731 295 1751 291 1753 275 1737 269 1740 252 1705 258 1704 273 1660 282 1641 275 1532 294 1403 230 1400 256 1399 271 1415 280.38 1415 284"
      />
      <polygon
        data-building="114D"
        data-floor="7"
        points="1403 230 1532 294 1641 275 1660 282 1704 273 1705 258 1740 252 1759 248 1761 232 1745 225.27 1747 212 1765 209 1765 204.97 1750 198 1752 185 1701 162 1704 145 1697 142 1697 140 1694.81 140 1695 142 1684 143 1684 156 1633 135 1557 150 1550 145 1478 158 1478 163 1408 177 1403 230"
      />
    </svg>
  )
}

export default Map
