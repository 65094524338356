import React, { useEffect } from 'react'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        data-building="114G"
        data-floor="0"
        d="M1336,648l-1-2-16-15,4-37-9-10-145,29-6-6-20,1-5-2v4.45L1167,645l112-23v-5l28-6,11,13-1,27ZM1088,510l-1,27,27,31v-5.89l21-3.11-43.08-49Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114G"
        data-floor="1"
        d="M1116.59,523.05l22.41-4-42-48-9,3-30,5v16.43l15,16.66,15-2.09h3.92l24.67,28.06ZM1326,586.43l2-36.43-10.32-11L1173,566l-4-2-26.22,4-7.78-9-21,3.11,0,15.89,24,28,5,2,20-1,6,6,145-29,9,10-4,37,16,15,1,2,44-10Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114G"
        data-floor="2"
        d="M1371.5,593.5l1.24-9.5L1330,545V508l-9-9-147,24h-3l-26,2-6-6-22.41,4v15L1142.78,568l26.22-4,4,2,144.68-27L1328,550l-2,36.43L1380,638l29-6.53V629ZM1118.16,481.09l20.84-3.34L1120,458l-23-25.95-9,.95v3l-29,4v17l17,17,21-3,21.16,24.18Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114G"
        data-floor="3"
        d="M1376,547v-9l-55-44.56,1.28,1L1325,456l-147,24-4-1-27,5-8-6.22-20.84,3.34v14.09L1145,525l29-2,147-24,9,9v37l42.74,39-1.24,9.5L1409,629l6-49ZM1120,438.24l25-4.24-23-20-23-23.68-7,1.68v4.27L1060,400v16.15L1077,434l11-1,9-.95L1120,458Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114G"
        data-floor="4"
        d="M1382,501v-3l-57-45.13,3-38.87-148,22-4-1-25,4-6-5-25,4.24V458l19,19.75,8,6.22,27-5,4,1,147-24-2.72,38.48L1376,538v9l39,33,6-49ZM1122,394.18l25-5.18-46-40-12,2v4.91L1062,360v15.79L1079,393l13-1,7-1.68L1122,414Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114G"
        data-floor="5"
        d="M1386,452l-57-45,5-39-152.1,22.32L1178,389l-26,4-5-4-25,5.18V414l29,25,25-4,4,1,148-22-3,38.87L1382,498v3l39,30,5-46ZM1123.48,349l20.52-3-43-39-10,2v5l-29,4.82,1,17.18,17,16,9-1,12-2,22.48,20Z"
        transform="translate(0 0)"
      />
      <polygon
        data-building="114G"
        data-floor="6"
        points="1063 294 1081 310 1091 309 1101 307 1144 346 1123.48 349 1123.48 369 1141.99 384.65 1152 393 1178 389 1181.9  390.32 1334 368 1329 407 1368 437.79 1388.07 452.87 1426 485 1435 420 1638 384 1644 388 1653 386 1622 364 1614 367 1610 361 1610 358 1625 356 1625 352 1591 329 1591 318 1585 314 1583 308 1578 305 1573 304 1566 303 1562 300 1556 296 1538 298 1500 274 1483 276 1472 268 1473 257 1389 203 1096 243 1066 248 1066 251 1083 266 1096 265 1096 272 1064 276 1063 294"
      />
      <path
        data-building="114F"
        data-floor="0"
        d="M475.48,898H313l7.1,36H511l6-20v-4H477ZM767,687v12L665,718l-5-11L543,732l1,14-7,21,124-25,6,10,103-20V718.67L869,700V666.69Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114F"
        data-floor="1"
        d="M540.37,824,528,863l-58,1-3-20-164,3,10,51H475.48L477,910h57.18L554,839l-1-15ZM866,620,764,639v14L660,670l-1-11L537,682.88,538,698l-10,31,15-1v4l117-25,5,11,102-19V687l100.54-20Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114F"
        data-floor="2"
        d="M523,814l-60,1-1-13-166,1,7,44,164-3,3,20,58-1,12.37-39L545,807V792.35H529.57ZM762,595v11L657,625l-1-10L531,638l2,10-12,31,16-.09v4L659,659l1,11,104-17V639l102-19V577.51Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114F"
        data-floor="3"
        d="M528,729l-12,35H456l-1-10H220l4,25,67-1,5,25,166-1,1,13,60-1,6.57-21.65L544,746l-1-18ZM864.28,533,759,550v9L653,580l-1-10L526,592v5.56L515,630.2h15.6L531,638l125-23,1,10,105-19V595l104-17.49Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114F"
        data-floor="4"
        d="M521,679l-11,33-61,1-1-11H209l11,52H455l1,10h60l12-35,10-31-1-19.09ZM863,488,756.77,504.53,757,514,648.45,533l-.45-9L519,546l-11.48,31.51h17.39L526,592l96.82-16.9L652,570l1,10,106-21v-9l105.28-17Z"
        transform="translate(0 0)"
      />
      <path
        data-building="114F"
        data-floor="5"
        d="M515,630.2,505,661H442l-1-11-244-1,12,53H448l1,11,61-1,23-64-2.41-17.8ZM863,439,755,458v11L646,485l-5-8L507.52,498.1,503,507l-16,36,33-1-1,4,129-22,.45,9L757,514l-.23-9.47L863,488Z"
        transform="translate(0 0)"
      />
      <polygon
        data-building="114F"
        data-floor="6"
        points="197 649 441 650 442 661 505 661 526 597.56 524.91 577.51 507.52 577.51 519 546 520 542 487 543 507.52 498.1 641 477 646 485 755 469 755 458 863 439 861 375 808 311 822 309 820 304 807 289 718 302 454 339 452 336 296 355 288 375 288 378 307 378 287 409 264 409 247 437 247 442 266 442 235 491 210 491 189 526 189 529.76 211 530 183 575 197 649"
      />
    </svg>
  )
}

export default Map
