import React, { useEffect } from 'react'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-floor="0"
        data-building="114G"
        points="405 784 440 804 453 798 635 929 922 775 922 758.31 891 774 875 767 804 806 804 823 754 849 736 841 710 855 716 861 716 871 655 900 451.77 758.31 434 765 400 740 405 784"
      />
      <polygon
        data-floor="1"
        data-building="114G"
        points="367 720 400 740 434 765 451.77 758.31 655 900 716 871 716 861 710 855 736 841 754 849 804 823 804 806 875 767 891 774 922 758.31 960 737.04 960 720.92 937 708 889 729 869 721 801 761 802 779 750.23 804 730 792 702 806 708 812 707 829 651 854 447 719 429 724 360 677 360 664.29 346 670 348 686 368 702 367 720"
      />
      <polygon
        data-floor="2"
        data-building="114G"
        points="360 664.29 360 677 429 724 447 719 651 854 707 829 708 812 702 806 730 792 750.23 804 802 779 801 761 869 721 889 729 937 708 960 695.24 960 678.47 935.24 664.29 889 687 868 677 796 712 802 716 802 732 749 757 728 746 700.09 759.14 703 764 704 780 647 806 440 675 424 682 354 632 354 623.23 339 628 342 646 360 658 360 664.29"
      />
      <polygon
        data-floor="3"
        data-building="114G"
        points="354 623.23 354 632 424 682 440 675 647 806 704 780 703 764 700.09 759.14 728 746 749 757 802 732 802 716 796 712 868 677 889 687 935.24 664.29 960 651.31 960 633.38 933 621 887 641 867 632 795.92 664.29 798 667 799 684 745 710 724 698 697 712 701 716 701 733 645 759 434 630 418 636 347 588 347 580.13 332 586 336 604 353 616 354 623.23"
      />
      <polygon
        data-floor="4"
        data-building="114G"
        points="347 580.13 347 588 418 636 434 630 645 759 701 733 701 716 697 712 724 698 745 710 799 684 798 667 795.92 664.29 867 632 887 641 933 621 960 606.9 960 588.77 931 576 887 593 866 585 794 617 795 635 743 660 721 648 692 659 697 662 697 683 641 709 428 584 412 590 341 542 341 536.98 326 544 328 560 346 573.64 347 580.13"
      />
      <polygon
        data-floor="5"
        data-building="114G"
        points="341 536.98 341 542 412 590 428 584 641 709 697 683 697 662 692 659 721 648 743 660 795 635 794 617 826.37 602.62 866 585 887 593 931 576 960 561.93 960 542.07 929 529 886 545 865.65 536.98 793 570 793 591 741 611 720 602 693 614 694 634 637 658 423 536 406 543 334 496 334 491.63 320 498 322 513 341 527 341 536.98"
      />
      <polygon
        data-floor="6"
        data-building="114G"
        points="334 491.63 334 496 406 543 423 536 637 658 694 634 693 614 720 602 741 611 793 591 793 570 865.65 536.98 886 545 929 529 960 514.85 960 496.38 926 481 960 467.72 960 463.26 923 448 918 450 817 406 832 401 831 396 787 379 777 381 762 374 624 422 606 414 605 386 554 362 554 358 513 340 446 361 444 351 434 345 419 350 419 370 328 399 328 412.55 308 421 308 424.29 334 439 334 445 313 452 315 469 333 481 334 491.63"
      />
      <polygon
        data-floor="0"
        data-building="114F"
        points="996 553 996 584.49 1135 648 1146 642 1389 520 1389 506.6 1378 512 1363 508 1344 517 1344 525 1236.12 577.3 1220 574 1201 584 1206 587 1206 597 1143 630 1109 615 1110 605 996 553"
      />
      <polygon
        data-floor="1"
        data-building="114F"
        points="996 553 1110 605 1109 615 1143 630 1206 597 1206 587 1201 584 1220 574 1236.12 577.3 1344 525 1344 517 1363 508 1378 512 1389 506.6 1424 488 1424 473.98 1406 463 1384 475 1362 469 1347 476 1347 481 1239 538 1219.47 533 1201 542 1202 558 1146 588 1111 574 1111 560 996 512.23 996 553"
      />
      <polygon
        data-floor="2"
        data-building="114F"
        points="996 512.23 1111 560 1111 574 1146 588 1202 558 1201 542 1219.47 533 1239 538 1347 481 1347 476 1362 469 1384 475 1406 463 1428 452 1428 436.27 1409 426 1387 437 1366 431 1350 440 1350 445 1242 499 1221 494 1204 502 1205 520 1147.77 546.5 1112.29 533 1112 521 996 472.36 996 512.23"
      />
      <polygon
        data-floor="3"
        data-building="114F"
        points="996 472.36 1112 521 1112.29 533 1147.77 546.5 1205 520 1204 502 1221 494 1242 499 1350 445 1350 440 1366 431 1387 437 1409 426 1433 414 1433 398.49 1412 388 1390 398 1369 392 1354 400 1355 408 1245 459 1224 452 1207 462 1208 479 1148 505 1114 492 1114 480 996 433.79 996 472.36"
      />
      <polygon
        data-floor="4"
        data-building="114F"
        points="996 433.79 1114 480 1114 492 1148 505 1208 479 1207 462 1224 452 1245 459 1355 408 1354 400 1369 392 1390 398 1412 388 1437 376 1437 361.13 1414 350 1394 359 1372 354 1358 361 1358 367 1248 417 1227 412 1210 419.65 1211 438 1152 464 1116 450 1116 437 996 394.3 996 433.79"
      />
      <polygon
        data-floor="5"
        data-building="114F"
        points="996 394.3 1116 437 1116 450 1152 464 1211 438 1210 419.65 1227 412 1248 417 1358 367 1358 361 1372 354 1394 359 1414 350 1441 338 1441 322.93 1416 311 1399 320 1377 313 1361 321 1361 326 1252 374 1232 369 1212 377.84 1212 391.72 1154 418 1117 406 1117 396 997 352 996 394.3"
      />
      <polygon
        data-floor="5"
        data-building="114F"
        points="1104 233 1127 247 1123 249 1113 245 1067.15 261.81 1052 252 1052 237.09 1080.18 233 1086 237 1104 233"
      />
      <polygon
        data-floor="6"
        data-building="114F"
        points="997 352 1117 396 1117 406 1154 418 1212 391.72 1212 377.84 1232 369 1252 374 1361 326 1361 321 1377 313 1399 320 1416 311 1446 299 1446 283.64 1424 274 1413 278 1413.06 274 1448 260 1448 255.96 1423 245 1417 246 1387 230 1387 226 1360 211 1352 212 1316 193 1316 188 1290 175 1283 176 1233 148 1083 169 1082 173 1055 176 1055 179 1080 195 1054 201 1054 215 1086 237 1104 233 1127 247 1123 249 1113 245 1053 267 1053 274 997 296.03 997 352"
      />
    </svg>
  )
}

export default Map
