import React, { RefObject, useMemo, useState } from 'react'

import { Formik } from 'formik'
import { toFormikValidate } from 'zod-formik-adapter'

import { MultiOptionSelect } from 'components/atoms/MultiOptionSelect'

import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'

import { H3Bold, TextBody2 } from 'components/atoms/Typography/Typography.style'
import { Button } from 'components/atoms/Button'
import { Filters, filtersSchema } from 'hooks/useFilters'
import { toSorted } from 'utils/array'
import { ReactComponent as Close } from 'assets/icons/close-black.svg'
import {
  ButtonContainer,
  FindFlatContainer,
  FindFlatWraper,
  InputRangeContainer,
  InputRangeLable,
  InputRangeWraper,
  SelectContainer,
  StyledForm,
  InputContainer,
  ResetFiltersButton,
} from './FindFlat.styles'

type FindFlatProps = {
  HeroLableRef: RefObject<HTMLDivElement>
  availableFilters: Filters
  currentFilters: Filters
  onFiltersChange: (filters: Filters) => void
  resetFilters: () => void
  headingTag?: keyof JSX.IntrinsicElements
}

export const FindFlat: React.FC<FindFlatProps> = ({
  HeroLableRef,
  availableFilters,
  currentFilters,
  onFiltersChange,
  resetFilters,
  headingTag,
}) => {
  const initialValues: Filters = useMemo(() => {
    return {
      rooms:
        currentFilters.rooms.length === availableFilters.rooms.length
          ? []
          : currentFilters.rooms,
      floor:
        currentFilters.floor.length === availableFilters.floor.length
          ? []
          : currentFilters.floor,
      area: {
        min: currentFilters.area.min,
        max: currentFilters.area.max,
      },
    }
  }, [currentFilters])

  const onFormSubmit = (values: Filters) => {
    onFiltersChange({
      rooms: values.rooms.length === 0 ? availableFilters.rooms : values.rooms,
      floor: values.floor.length === 0 ? availableFilters.floor : values.floor,
      area: values.area,
    })
  }

  const [resetKey, setResetKey] = useState(0)

  const handleResetFilters = () => {
    resetFilters()
    setResetKey((prevKey) => prevKey + 1)
  }

  return (
    <FindFlatWraper>
      <FindFlatContainer ref={HeroLableRef}>
        <H3Bold as={headingTag}>
          <span className="text-green">Znajdź</span> mieszkanie
        </H3Bold>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validate={toFormikValidate(filtersSchema)}
          onSubmit={(values) => onFormSubmit(values)}
          key={resetKey}
        >
          {({
            values,
            // errors,
            // touched,
            // handleChange,
            // handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <StyledForm onSubmit={handleSubmit}>
              <SelectContainer>
                <TextBody2>Pokój</TextBody2>
                <MultiOptionSelect
                  options={availableFilters.rooms.map((room) => ({
                    label: `${room}`,
                    value: `${room}`,
                  }))}
                  selected={values.rooms.map((room) => `${room}`)}
                  label={
                    values.rooms.length !== 0
                      ? `Pokoje: ${values.rooms.join(', ')}`
                      : 'Wybierz'
                  }
                  onValueClick={(value) => {
                    if (values.rooms.includes(Number(value))) {
                      setFieldValue(
                        'rooms',
                        values.rooms.filter((room) => room !== Number(value))
                      )
                    } else {
                      setFieldValue(
                        'rooms',
                        toSorted([...values.rooms, Number(value)])
                      )
                    }
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <TextBody2>Piętro</TextBody2>
                <MultiOptionSelect
                  options={availableFilters.floor.map((floor) => ({
                    label: `${floor}`,
                    value: `${floor}`,
                  }))}
                  selected={values.floor.map((floor) => `${floor}`)}
                  label={
                    values.floor.length !== 0
                      ? `Piętra: ${values.floor.join(', ')}`
                      : 'Wybierz'
                  }
                  onValueClick={(value) => {
                    if (values.floor.includes(value)) {
                      setFieldValue(
                        'floor',
                        values.floor.filter((floor) => floor !== value)
                      )
                    } else {
                      setFieldValue('floor', toSorted([...values.floor, value]))
                    }
                  }}
                />
              </SelectContainer>

              <InputContainer>
                <InputRangeContainer>
                  <TextBody2>Powierzchnia</TextBody2>
                  <InputRangeWraper>
                    <InputRange
                      minValue={availableFilters.area.min}
                      maxValue={availableFilters.area.max}
                      step={1}
                      value={values.area}
                      onChange={(value) => setFieldValue('area', value)}
                    />
                  </InputRangeWraper>
                  <InputRangeLable>
                    <TextBody2>
                      {availableFilters.area.min} m<sup>2</sup>
                    </TextBody2>
                    <TextBody2>
                      {availableFilters.area.max} m<sup>2</sup>
                    </TextBody2>
                  </InputRangeLable>
                </InputRangeContainer>

                <ButtonContainer>
                  <Button noMaxWidth TextButton2>
                    Pokaż mieszkania
                  </Button>
                  <ResetFiltersButton onClick={handleResetFilters}>
                    <Close />
                    <TextBody2>Wyczyść filtry</TextBody2>
                  </ResetFiltersButton>
                </ButtonContainer>
              </InputContainer>
            </StyledForm>
          )}
        </Formik>
      </FindFlatContainer>
    </FindFlatWraper>
  )
}
