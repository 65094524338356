import React, { useMemo } from 'react'
import styled from 'styled-components'

import { PageProps, graphql, navigate } from 'gatsby'

import { Layout } from 'views/Layout'
import { Container, ViewContainer } from 'components/atoms/Container'
import { Seo } from 'components/atoms/Seo'
import { Contact } from 'components/organisms/Contact'
import { Search } from 'components/organisms/SearchFlat'

import useFilters from 'hooks/useFilters'
import useFlatContext from 'hooks/useFlatContext'

// @ts-ignore
import Browser from 'browser'
// @ts-ignore
import Investment from 'investments/miasteczko'

import media from 'styles/media'

import type { Flat } from 'types/domain'

const BrowserContainer = styled(Container)`
  max-width: none;
  height: 90vh;
  min-height: 551px;

  ${media.xxl.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
  ${media.sm.max} {
    padding-top: 49px;
    padding-bottom: 32px;
  }
`

const SearchPage: React.FC<PageProps<Queries.SearchPageQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.searchEngine!
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) {
    throw new Error("CMS data didn't load properly")
  }

  const { flats, availableFlats, addToFavourites, removeFromFavourites } =
    useFlatContext()
  const {
    filteredFlats: filteredAvailableFlats,
    availableFilters: availableFlatsAvailableFilters,
    currentFilters: availableFlatsCurrentFilters,
    setCurrentFilters: setAvailableFlatsCurrentFilters,
    filterFlats: filterAvailableFlats,
    resetFilters,
  } = useFilters(availableFlats)

  const filteredFlats3D = useMemo(() => {
    const filteredAvailableFlats3D = filterAvailableFlats(availableFlats, [
      'area',
      'rooms',
    ])
    return [
      ...filteredAvailableFlats3D,
      ...flats.filter((flat) => flat.status === 'sold'),
    ]
  }, [flats, availableFlats, filteredAvailableFlats])

  const ContactData = {
    heading: PAGE?.searchEngineFormTitle!,
    formTitle: PAGE?.searchEngineFormTitle!,
    image: {
      src: PAGE?.searchEngineFormImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PAGE?.searchEngineFormImg?.altText!,
    }!,
  }

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <ViewContainer breakpoint="lg">
        <BrowserContainer margin={[70, 0]}>
          <Browser
            investment={Investment}
            flats={filteredFlats3D}
            enterFlat={(flat: Flat) => {
              navigate(`/mieszkanie/${flat.urlID}`)
            }}
            addToFavourites={addToFavourites}
            removeFromFavourites={removeFromFavourites}
            extendNavigation
          />
        </BrowserContainer>
      </ViewContainer>

      <Search
        flats={filteredAvailableFlats}
        currentFilters={availableFlatsCurrentFilters}
        setCurrentFilters={setAvailableFlatsCurrentFilters}
        availableFilters={availableFlatsAvailableFilters}
        resetFilters={resetFilters}
        searchTag="h2"
        investmentsTag="h1"
      />
      <Contact
        heading={ContactData.heading}
        formTitle={ContactData.formTitle}
        image={ContactData.image}
      />
    </Layout>
  )
}

export const query = graphql`
  query SearchPage {
    wpPage(slug: { regex: "/wyszukiwarka/" }) {
      searchEngine {
        searchEngineFormTitle
        searchEngineFormFormsTitle
        searchEngineFormImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
  }
`

export default SearchPage
