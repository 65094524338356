import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-floor="0"
        data-building="114G"
        points="900 539 868 550 858 546 850 549 842 545 760 575 760 582.25 724 593 679 568 679 553.72 641.56 533 627 536 597 520 598 508 593 505 579 509 575 508 575 520.3 643 560 653 556 743 609 838 570 850 577 894 560 897 543 899 542 900 539"
      />
      <polygon
        data-floor="1"
        data-building="114G"
        points="552 493 575 508 579 509 593 505 598 508 597 520 627 536 641.56 533 679 553.72 679 568 724 593 760 582.25 760 575 842 545 850 549 858 546 868 550 900 539 911 535 911 527.54 894 520 893.81 505 867 513 852 506 841 509 758 538 758 545 723 556 677 531 677 516 680 515 644 495 625 501 597 485 597 472 600 471 594 468 574 473 570 471 570 475 552 480.15 552 493"
      />
      <polygon
        data-floor="6"
        data-building="114G"
        points="536 313 560 324 560 323 562 324 582 320 585 321 585 336 612 348 633 343 667 360 667 375 717 398 751 388 751 379 837 353 849 354 863 359 896.38 349 909 345 909 330.82 896 326 896 311 907 307 907 304 896 300 896 298 938 286 942.7 286 942.7 282.16 984 270 984 222.8 935 206 935 191 927 189 927 186 925 186 925 188 914 191 914 201 876 187 837 197 827 193 785 204 775 200 766 200 734 208 734 214 700 222 700 229 680 234 670 230 664 230 552 258 552 267 532 272 532 275.37 555 285 554.64 292 535 297 536 313"
      />
      <path
        data-floor="2"
        data-building="114G"
        d="M944,389h-5.58L897,403l14,5.07v13.7L926,416l12-2,46-16V378.5Zm-51.1,79L866,476l-15-7-14,3-81,27,0,9-35,10-47-24V478l4-1-36-19-19,7-29-17V435l3-1-5-2-20,5-5-2v4l-17,6v15l20,11,4,2,20-5,6,3-3,1v13l28,16,19-6,36,20-3,1v15l46,25,35-11v-7l83-29,11-3,15,7,26.81-8L927,492l-34-16Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="3"
        data-building="114G"
        d="M943,355l-5-1-41,12,14,1.08V383l-14.06,4,.06,16,41.42-14H944l40-10.5V343.13Zm-80,83-12.21-6L837,435l-83,27v5l-36,12-46-26V439l-33.08-18L619,426l-27-14V396l-3-1-20,5-6-2v7l-19,4.32V421l23,14,5,2,20-5,5,2-3,1v13l29,17,19-7,36,19-4,1v16l47,24,35-10,0-9,81-27,14-3,15,7,26.9-8V456.27L924,448v-3.75L892,430Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="4"
        data-building="114G"
        d="M897,403l-.06-16L864,397l-15-4-12-1-84,27v8l-37,10-46-21V400l-35-17-19,4-29-13V359l-2-1-21,4-3-2v7l-18,5.44V387l20,11,6,2,20-5,3,1v16l27,14,19.92-5L672,439v14l46,26,36-12v-5l83-27,13.79-3L863,438l29-8v-1.14l19-7.09v-13.7L902,405Zm46.41-83L940,318.36l-35.44,10.81,4.44,1.65V345l-12.62,4,.62,17,41-12,5,1,41-11.87V308.2Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="5"
        data-building="114G"
        d="M942.7,282.16V286H938l-42,12v2l11,4v3l-11,4v15l8.56,3.17L940,318.36l3.41,1.64L984,308.2V270ZM896.38,349,863,359l-14-5-12-1-86,26v9l-34,10-50-23V360l-34-17-21,5-27-12V321l-3-1-20,4-2-1v6l-21,6v14l22,11,3,2,21-4,2,1v15l29,13,19-4,35,17v16l46,21,37-10v-8l84-27,12,1,15,4,45-13,2-1V367.08L897,366Z"
        transform="translate(0 0)"
      />
      <polygon
        data-floor="0"
        data-building="114F"
        points="1612 824 1241 912 934 722 934 718.91 931 718 931 703.83 954 717 975 708 1003 723 993 726 993 742 1136 822 1149 815 1174 830 1164 836 1164 853 1241 896 1282 883 1282 868 1341 854 1352 864 1410 852 1410 838 1486 821 1498 831 1552 820 1554 805 1617 790 1612 824"
      />
      <polygon
        data-floor="1"
        data-building="114F"
        points="1617 790 1554 805 1552 820 1498 831 1486 821 1410 838 1410 852 1352 864 1341 854 1282 868 1282 883 1241 896 1164 853 1164 836 1174 830 1149 815 1136 822 993 742 993 726 1003 723 975 708 954 717 931 703.83 896 684 896 667.96 920 658 954 676 974 668 1003 681 994 684 994 701 1142 778 1151 773 1176 786 1166 790 1165.68 807.91 1245 850 1285 838 1285 823 1344 811 1355 822 1416 809 1416 794 1490.55 777 1504 788 1559.17 777 1561 762 1624 749 1617 790"
      />
      <polygon
        data-floor="2"
        data-building="114F"
        points="1624 749 1561 762 1559.17 777 1504 788 1490.55 777 1416 794 1416 809 1355 822 1344 811 1285 823 1285 838 1245 850 1165.68 807.91 1166 790 1176 786 1151 773 1142 778 994 701 994 684 1003 681 974 668 954 676 920 658 895 644 895 627.7 921 617 953 633 975 626 1002 639 996 641 996.09 658 1142.06 733.5 1153 728 1181 740 1169 745 1169 763 1245 802 1288 793 1288 778 1349 766 1359 775 1419 763 1419 750 1497.43 733.5 1509 743 1565 732 1567 718 1631 705 1624 749"
      />
      <polygon
        data-floor="3"
        data-building="114F"
        points="1631 705 1567 718 1565 732 1509 743 1497.43 733.5 1419 750 1419 763 1359 775 1349 766 1288 778 1288 793 1245 802 1169 763 1169 745 1181 740 1153 728 1142.06 733.5 996.09 658 996 641 1002 639 975 626 953 633 921 617 895 603.39 895 584.94 922 575 953 591 976 584 1001 597 994 601 994 613 1143.11 688.5 1155 682 1179 694 1175 696 1174 718 1249 754 1292 745 1292 732 1354 720 1366 729 1425 717 1425 705 1502 687 1516 697 1571 687 1573 673 1637 661 1631 705"
      />
      <polygon
        data-floor="4"
        data-building="114F"
        points="1637 661 1573 673 1571 687 1516 697 1502 687 1425 705 1425 717 1366 729 1354 720 1292 732 1292 745 1249 754 1174 718 1175 696 1179 694 1155 682 1143.11 688.5 994 613 994 601 1001 597 976 584 953 591 922 575 894 560 897 543 922.38 533 954 548 974 541 999 551 999 571 1143 641 1157 635 1182 647 1177 649 1177 671 1254 708 1296 696 1296 685 1360 672 1369 680 1431 668 1430.45 658 1510 641 1521 649 1580 639 1580 627 1643 615 1637 661"
      />
      <polygon
        data-floor="5"
        data-building="114F"
        points="1643 615 1580 627 1580 639 1521 649 1510 641 1430.45 658 1431 668 1369 680 1360 672 1296 685 1296 696 1254 708 1177 671 1177 649 1182 647 1157 635 1143 641 999 571 999 551 974 541 954 548 911 527.54 894 520 893.81 505 927 492 952 504 975 496 998 506 997 507 997 522 1149 593 1160 587 1184 598 1178.28 600 1178.28 617 1257 656 1299 645 1300 635 1365 624 1375 631 1435 621 1435 612 1516 594 1527 601 1585 592 1586 582 1651 569 1643 615"
      />
      <polygon
        data-floor="6"
        data-building="114F"
        points="1651 569 1586 582 1585 592 1527 601 1516 594 1435 612 1435 621 1375 631 1365 624 1300 635 1299 645 1257 656 1178.28 617 1178.28 600 1184 598 1160 587 1149 593 997 522 997 507 998 506 975 496 952 504 893 476 892.9 456.27 924 448 924 444.25 892 430 892 428.86 926 416 938 414 1028 382 1081 366 1158 393 1167 390 1242 418 1241 427 1323 458 1400 445 1400 441 1398 439 1400 436 1450 429 1450 426 1523 414 1533 415 1556 430 1556 434 1664 502 1651 569"
      />
    </svg>
  )
}

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
}

export default Map
