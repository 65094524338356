import React, { useEffect } from 'react'

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
      preserveAspectRatio="xMidYMid slice"
    >
      <polygon
        data-floor="6"
        data-building="114"
        points="32 498 62 510 82 519 125 503 142 510 132 514 133 529 170 545 197 535 210 539 200 544 200 560 289 600 317 589 406 626 396 631 397 647 435 663 517 626 516 607 513 606 579 574 601 582 646 561 670 551 670 546 635 533 627 535 625 490 527 456 532 453 493 438 485 441 474 438 531 417 571 400 587 405 627 392 627 388 620 387 620 381 669 362 669 359 660 357 660 352 710 334 725 337 725 350 759 360 818 374 833 368 924 396 915 400 915 404 950 413 998 390 998 388 982 383 1033 359 1052 363 1096 342 1096 329 1064 321 1096 305 1096 301 1056 290 792 226 792 212 740 200 531 270 533 312 517 307 501 311 502 327 489 332 478 330 412 352 412 360 407 361 393 356 392 338 381 336 369 339 369 348 331 336 20 440 32 498"
      />
      <polygon
        data-floor="0"
        data-building="114A"
        points="1072 387 1096 394 1096 412 1150 428 1160 423 1220 438 1283 401 1283 389 1272 393 1257 389 1222 410 1161 394 1152 399 1148 398 1138 402 1101 392 1101 388 1081.34 382.33 1072 387"
      />
      <polygon
        data-floor="1"
        data-building="114A"
        points="1081 382 1101 388 1101 392 1138 402 1148 398 1152 399 1161 394 1222 410 1257 389 1272 393 1283 389 1304 377 1304 364 1285 361 1285 356 1277 360 1259 357 1223 376 1161 361 1138 370 1102 359 1103 352 1082.14 348.61 1071 354 1098 362 1098 374 1081 382"
      />
      <polygon
        data-floor="2"
        data-building="114A"
        points="1082 349 1103 352 1102 359 1138 370 1161 361 1223 376 1259 357 1277 360 1306 345 1306 332 1287 329 1287 324 1280 327 1261 324 1225 343 1163 328 1140 337 1103 327 1103 320 1078.2 313.9 1064 321 1096 329 1096 342 1082 349"
      />
      <polygon
        data-floor="3"
        data-building="114A"
        points="1096 305 1078 314 1103 320 1103 327 1140 337 1163 328 1225 343 1261 324 1280 327 1287 324 1308 313 1308 301 1290 299 1290 289 1281 294 1262 290 1229 308 1164 295 1140 303 1104 293 1104 287 1011 264 987.1 273.3 1096 301 1096 305"
      />
      <polygon
        data-floor="4"
        data-building="114A"
        points="929.28 259.28 987 273 1011 264 1104 287 1104 293 1140 304 1164 295 1229 308 1262 290 1281 294 1290 289 1320 276 1320 263 1293 257 1294 232 1246 222 1246 214 1199 204 1194 208 1169 203 1168 197 1109 186 1109 181 1042 167 1011 178 993 176 949 192 949 200 929 208 929.28 259.28"
      />
      <path
        data-floor="4"
        data-building="114"
        d="M1072,387l-21,10-18-5-34,18v13l-50,26-34-10V424l-81-22-17,8-54-17-37-10V369l-14-3-45,17,1,14-38,15v13l-39,16-18-6-41,18-5,3,44,15,5-2,16,6,40-15V445l43-17V415l39-17,17,3-3,2v11l89,29,17-7,81,22v15l35,11,49-25V445l33-19,19,4,21-10,24-12V394ZM606,622l-23-8-67,34,4,1,1,17-82,40-36-16-1-16,10-6-90-40-27,10-88-40V584l11-5-14-5-27,9-37-16-1-13,10-5-16-8L91,557,40,535l7,39,29.47,13.53L97,595l43-16,16,8-11,5,2,14,37,17,26-10,14,4-11,7,1,14,93,42,22-11,88,40-11,6,1,16,39,16,79-39-1-18-5-2,67-35,22,9,44-22,29-15V611l-31-9Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="0"
        data-building="114"
        d="M618,779l-22-8-66,36,11,7v13l-85,46-36-20,1-16,12-7-89-45-24,12-87-46-1-13,12-7-15-6-24,9-32-14-1-12,10-5-22-12-40,16-13-6v8L421,864l-2,2v40h5l114-58,108-63-1-22Zm431-252-18-4-33,19v16l-47,26-36-11V561l-78-26-16,8-95-30V500l8-2-16-5-41,19v12l-28.16,14.14,11.26,4.18L705,518l242,90,112-67V522Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="1"
        data-building="114"
        d="M616,739l-23-7-66,37,7,2,1,15-81,42-36-16-1-16,10-6-87-43-26,12-88-44-1-14,12-7-14-6-26,11-37-18-1-14,11-6-17-8-41,18L90,660l-20,6v15l37,20,13,6,40-16,22,12-10,4,1,13,32,14,24-9,15,6-12,7,1,13,87,46,24-12,89,45-12,7-1,16,36,20,85-46V814l-11-7,66-36,22,8,28-16,41-25V727l-28-10Zm457-255-24,12-18-5-34,19v16l-47,26-35-12V528l2-1-81-26-15,8-91-28V470l7-2-20-6-41,20v12l-37,18H629l-3,.1,1,22.9,8-2,14,5,28-14V512l41-19,16,5-8,2v13l95,30,16-8,78,26v12l36,11,47-26V542l33-19,18,4,10-5,33-19V490Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="2"
        data-building="114"
        d="M612,702l-23-9-69,35,10,4,1,16-81,42-37-19-1-15,10-6-87-41-26,11-90-44-1-13,14-7-15-6-27,12-36-16-2-15,10-6-16-8-39,16L57,610l5,36,50,25,41-18,17,8-11,6,1,14,37,18,26-11,14,6-12,7,1,14,88,44,26-12,87,43-10,6,1,16,36,16,81-42-1-15-7-2,66-37,23,7,43-22,22-14V688l-24-10Zm481-242-21-8-21,12-20-6-32,19v16l-50,26-34-11V493l-79-25-17,8-52-17-40-12v-8l9-4-21-5-39,17v14l-41,19,1,13-10.66,4.58L626,512h13l37-18V482l41-20,20,6-7,2v11l91,28,15-8,81,26-2,1v12l35,12,47-26V510l34-19,18,5,24-12,21-11Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="3"
        data-building="114"
        d="M608,662l-22-9-67,35,5,2,1,18-79,39-39-16-1-16,11-6-88-40-22,11-93-42-1-14,11-7-14-4-26,10-37-17-2-14,11-5-16-8L97,595l-22-8-22,7,4,16,50,23,39-16,16,8-10,6,2,15,36,16,27-12,15,6-14,7,1,13,90,44,26-11,87,41-10,6,1,15,37,19,81-42-1-16-10-4,69-35,23,9,45-24,22-12V650l-27-10Zm464-242-21,10-19-4-33,19v14l-49,25-35-11V459l-81-23-17,7-89-29V403l3-2-17-3-39,17v13l-43,17v15l-40,15-16-6-4.9,2.3L625,490v8l11-5-1-13,41-19V447l39-17,21,5-9,4v8l92,29,17-8,79,25v15l34,11,50-26V477l32-19,20,6,21-12,22-12V428Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="5"
        data-building="114"
        d="M1071.13,353.87,1052,363l-19-4-51,24,16,5v2l-48,23-35-9v-4l9-4-91-28-15,6-93-24V337l-15-3-50,18v5l9,2v3l-49,19v6l7,1v4l-40,13-16-5-28.27,12-21.57,8.62L474,438l11,3,8-3,39,15,41-18,18,6,39-16V412l38-15-1-14,45-17,14,3v14l91,27,17-8,81,22v15l34,10,50-26V410l34-18,18,5,21-10,26-13V362ZM601,582l-22-8-66,32,3,1,1,19-82,37-38-16-1-16,10-5-89-37-28,11-89-40V544l10-5-13-4-27,10-37-16-1-15,10-4-17-7L82,519l-20-9L38,520l2,15,51,22,42-16,16,8-10,5,1,13,37,16,27-9,14,5-11,4v15l88,40,27-10,90,40-10,6,1,17,36,15,82-40V649l-5-1,67-34,23,8,44-20,23-12V572l-27-11Z"
        transform="translate(0 0)"
      />
    </svg>
  )
}

export default Map
