/* eslint-disable global-require */
import {
  Investment,
  Building,
  Floors,
  Floor,
} from '../../browser/schemes/index'

import { building106D, building106E, building106C } from '../lawenda'

// Building imports
import map1 from './maps/3d/map1'
import map2 from './maps/3d/map2'
import map3 from './maps/3d/map3'
import map4 from './maps/3d/map4'

import map1A from './maps/3d/map1A'
import map2A from './maps/3d/map2A'
import map3A from './maps/3d/map3A'

import map1B from './maps/3d/map1B'
import map2B from './maps/3d/map2B'
import map3B from './maps/3d/map3B'

import map1V from './maps/3d/map1-V'
import map2V from './maps/3d/map2-V'
import map3V from './maps/3d/map3-V'
import map4V from './maps/3d/map4-V'

import { ReactComponent as investmentMap } from './maps/budynki_mj.svg'

import { ReactComponent as p0 } from './maps/floors/p0.svg'
import { ReactComponent as p1 } from './maps/floors/p1.svg'
import { ReactComponent as p2 } from './maps/floors/p2.svg'
import { ReactComponent as p3 } from './maps/floors/p3.svg'
import { ReactComponent as p4 } from './maps/floors/p4.svg'
import { ReactComponent as p5 } from './maps/floors/p5.svg'
import { ReactComponent as p6 } from './maps/floors/p6.svg'

import { ReactComponent as Ap0 } from './maps/floors/Ap0.svg'
import { ReactComponent as Ap1 } from './maps/floors/Ap1.svg'
import { ReactComponent as Ap2 } from './maps/floors/Ap2.svg'
import { ReactComponent as Ap3 } from './maps/floors/Ap3.svg'
import { ReactComponent as Ap4 } from './maps/floors/Ap4.svg'

import { ReactComponent as Bp0 } from './maps/floors/Bp0.svg'
import { ReactComponent as Bp1 } from './maps/floors/Bp1.svg'
import { ReactComponent as Bp2 } from './maps/floors/Bp2.svg'
import { ReactComponent as Bp3 } from './maps/floors/Bp3.svg'
import { ReactComponent as Bp4 } from './maps/floors/Bp4.svg'
import { ReactComponent as Bp5 } from './maps/floors/Bp5.svg'
import { ReactComponent as Bp6 } from './maps/floors/Bp6.svg'

import { ReactComponent as Cp0 } from './maps/floors/Cp0.svg'
import { ReactComponent as Cp1 } from './maps/floors/Cp1.svg'
import { ReactComponent as Cp2 } from './maps/floors/Cp2.svg'
import { ReactComponent as Cp3 } from './maps/floors/Cp3.svg'
import { ReactComponent as Cp4 } from './maps/floors/Cp4.svg'
import { ReactComponent as Cp5 } from './maps/floors/Cp5.svg'
import { ReactComponent as Cp6 } from './maps/floors/Cp6.svg'
import { ReactComponent as Cp7 } from './maps/floors/Cp7.svg'

import { ReactComponent as Dp0 } from './maps/floors/Dp0.svg'
import { ReactComponent as Dp1 } from './maps/floors/Dp1.svg'
import { ReactComponent as Dp2 } from './maps/floors/Dp2.svg'
import { ReactComponent as Dp3 } from './maps/floors/Dp3.svg'
import { ReactComponent as Dp4 } from './maps/floors/Dp4.svg'
import { ReactComponent as Dp5 } from './maps/floors/Dp5.svg'
import { ReactComponent as Dp6 } from './maps/floors/Dp6.svg'
import { ReactComponent as Dp7 } from './maps/floors/Dp7.svg'

import { ReactComponent as Ep0 } from './maps/floors/Ep0.svg'
import { ReactComponent as Ep1 } from './maps/floors/Ep1.svg'
import { ReactComponent as Ep2 } from './maps/floors/Ep2.svg'
import { ReactComponent as Ep3 } from './maps/floors/Ep3.svg'
import { ReactComponent as Ep4 } from './maps/floors/Ep4.svg'
import { ReactComponent as Ep5 } from './maps/floors/Ep5.svg'

import { ReactComponent as Fp0 } from './maps/floors/Fp0.svg'
import { ReactComponent as Fp1 } from './maps/floors/Fp1.svg'
import { ReactComponent as Fp2 } from './maps/floors/Fp2.svg'
import { ReactComponent as Fp3 } from './maps/floors/Fp3.svg'
import { ReactComponent as Fp4 } from './maps/floors/Fp4.svg'
import { ReactComponent as Fp5 } from './maps/floors/Fp5.svg'
import { ReactComponent as Fp6 } from './maps/floors/Fp6.svg'

import { ReactComponent as Gp0 } from './maps/floors/Gp0.svg'
import { ReactComponent as Gp1 } from './maps/floors/Gp1.svg'
import { ReactComponent as Gp2 } from './maps/floors/Gp2.svg'
import { ReactComponent as Gp3 } from './maps/floors/Gp3.svg'
import { ReactComponent as Gp4 } from './maps/floors/Gp4.svg'
import { ReactComponent as Gp5 } from './maps/floors/Gp5.svg'
import { ReactComponent as Gp6 } from './maps/floors/Gp6.svg'

import { ReactComponent as Hp0 } from './maps/floors/Hp0.svg'
import { ReactComponent as Hp1 } from './maps/floors/Hp1.svg'
import { ReactComponent as Hp2 } from './maps/floors/Hp2.svg'
import { ReactComponent as Hp3 } from './maps/floors/Hp3.svg'
import { ReactComponent as Hp4 } from './maps/floors/Hp4.svg'
import { ReactComponent as Hp5 } from './maps/floors/Hp5.svg'

import qualityBreakpoint1A_I from './assets/II-0.jpg'
import qualityBreakpoint2A_I from './assets/II-10.jpg'
import qualityBreakpoint3A_I from './assets/II-16.jpg'
import qualityBreakpoint4A_I from './assets/II-24.jpg'

import qualityBreakpoint1_III from './assets/III-16.jpg'
import qualityBreakpoint2_III from './assets/III-24.jpg'
import qualityBreakpoint3_III from './assets/III-36.jpg'

import qualityBreakpoint1_IV from './assets/IV-3.jpg'
import qualityBreakpoint2_IV from './assets/IV-17.jpg'
import qualityBreakpoint3_IV from './assets/IV-35.jpg'

import qualityBreakpoint1_V from './assets/V-8.jpg'
import qualityBreakpoint2_V from './assets/V-19.jpg'
import qualityBreakpoint3_V from './assets/V-30.jpg'
import qualityBreakpoint4_V from './assets/V-37.jpg'

const floors114 = new Floors({
  streets: {
    top: 'Łopuszańska',
    bottom: '',
    left: 'Jutrzenki',
    right: '',
  },
  windroseRotation: -20,
  list: [
    new Floor({
      level: 0,
      map: p0,
      backgroundImage: require('./assets/floors/p0.png').default,
    }),
    new Floor({
      level: 1,
      map: p1,
      backgroundImage: require('./assets/floors/p1.png').default,
    }),
    new Floor({
      level: 2,
      map: p2,
      backgroundImage: require('./assets/floors/p2.png').default,
    }),
    new Floor({
      level: 3,
      map: p3,
      backgroundImage: require('./assets/floors/p3.png').default,
    }),
    new Floor({
      level: 4,
      map: p4,
      backgroundImage: require('./assets/floors/p4.png').default,
    }),
    new Floor({
      level: 5,
      map: p5,
      backgroundImage: require('./assets/floors/p5.png').default,
    }),
    new Floor({
      level: 6,
      map: p6,
      backgroundImage: require('./assets/floors/p6.png').default,
    }),
  ],
})

const floors114A = new Floors({
  streets: {
    top: 'Łopuszańska',
    bottom: '',
    left: 'Jutrzenki',
    right: '',
  },
  windroseRotation: -20,
  list: [
    new Floor({
      level: 0,
      map: Ap0,
      backgroundImage: require('./assets/floors/Ap0.png').default,
    }),
    new Floor({
      level: 1,
      map: Ap1,
      backgroundImage: require('./assets/floors/Ap1.png').default,
    }),
    new Floor({
      level: 2,
      map: Ap2,
      backgroundImage: require('./assets/floors/Ap2.png').default,
    }),
    new Floor({
      level: 3,
      map: Ap3,
      backgroundImage: require('./assets/floors/Ap3.png').default,
    }),
    new Floor({
      level: 4,
      map: Ap4,
      backgroundImage: require('./assets/floors/Ap4.png').default,
    }),
  ],
})

const floors114B = new Floors({
  streets: {
    top: 'Łopuszańska',
    bottom: '',
    left: 'Jutrzenki',
    right: '',
  },
  windroseRotation: -20,
  list: [
    new Floor({
      level: 0,
      map: Bp0,
      backgroundImage: require('./assets/floors/Bp0.png').default,
    }),
    new Floor({
      level: 1,
      map: Bp1,
      backgroundImage: require('./assets/floors/Bp1.png').default,
    }),
    new Floor({
      level: 2,
      map: Bp2,
      backgroundImage: require('./assets/floors/Bp2.png').default,
    }),
    new Floor({
      level: 3,
      map: Bp3,
      backgroundImage: require('./assets/floors/Bp3.png').default,
    }),
    new Floor({
      level: 4,
      map: Bp4,
      backgroundImage: require('./assets/floors/Bp4.png').default,
    }),
    new Floor({
      level: 5,
      map: Bp5,
      backgroundImage: require('./assets/floors/Bp5.png').default,
    }),
    new Floor({
      level: 6,
      map: Bp6,
      backgroundImage: require('./assets/floors/Bp6.png').default,
    }),
  ],
})

const floors114C = new Floors({
  streets: {
    top: 'Jutrzenki',
    bottom: '',
    left: '',
    right: 'Łopuszańska',
  },
  windroseRotation: 30,
  list: [
    new Floor({
      level: 0,
      map: Cp0,
      backgroundImage: require('./assets/floors/Cp0.png').default,
    }),
    new Floor({
      level: 1,
      map: Cp1,
      backgroundImage: require('./assets/floors/Cp1.png').default,
    }),
    new Floor({
      level: 2,
      map: Cp2,
      backgroundImage: require('./assets/floors/Cp2.png').default,
    }),
    new Floor({
      level: 3,
      map: Cp3,
      backgroundImage: require('./assets/floors/Cp3.png').default,
    }),
    new Floor({
      level: 4,
      map: Cp4,
      backgroundImage: require('./assets/floors/Cp4.png').default,
    }),
    new Floor({
      level: 5,
      map: Cp5,
      backgroundImage: require('./assets/floors/Cp5.png').default,
    }),
    new Floor({
      level: 6,
      map: Cp6,
      backgroundImage: require('./assets/floors/Cp6.png').default,
    }),
    new Floor({
      level: 7,
      map: Cp7,
      backgroundImage: require('./assets/floors/Cp7.png').default,
    }),
  ],
})

const floors114D = new Floors({
  streets: {
    top: 'Jutrzenki',
    bottom: '',
    left: '',
    right: 'Łopuszańska',
  },
  windroseRotation: 30,
  list: [
    new Floor({
      level: 0,
      map: Dp0,
      backgroundImage: require('./assets/floors/Dp0.png').default,
    }),
    new Floor({
      level: 1,
      map: Dp1,
      backgroundImage: require('./assets/floors/Dp1.png').default,
    }),
    new Floor({
      level: 2,
      map: Dp2,
      backgroundImage: require('./assets/floors/Dp2.png').default,
    }),
    new Floor({
      level: 3,
      map: Dp3,
      backgroundImage: require('./assets/floors/Dp3.png').default,
    }),
    new Floor({
      level: 4,
      map: Dp4,
      backgroundImage: require('./assets/floors/Dp4.png').default,
    }),
    new Floor({
      level: 5,
      map: Dp5,
      backgroundImage: require('./assets/floors/Dp5.png').default,
    }),
    new Floor({
      level: 6,
      map: Dp6,
      backgroundImage: require('./assets/floors/Dp6.png').default,
    }),
    new Floor({
      level: 7,
      map: Dp7,
      backgroundImage: require('./assets/floors/Dp7.png').default,
    }),
  ],
})

const floors114E = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: '',
    right: 'Łopuszańska',
  },
  windroseRotation: -40,
  list: [
    new Floor({
      level: 0,
      map: Ep0,
      backgroundImage: require('./assets/floors/Ep0.png').default,
    }),
    new Floor({
      level: 1,
      map: Ep1,
      backgroundImage: require('./assets/floors/Ep1.png').default,
    }),
    new Floor({
      level: 2,
      map: Ep2,
      backgroundImage: require('./assets/floors/Ep2.png').default,
    }),
    new Floor({
      level: 3,
      map: Ep3,
      backgroundImage: require('./assets/floors/Ep3.png').default,
    }),
    new Floor({
      level: 4,
      map: Ep4,
      backgroundImage: require('./assets/floors/Ep4.png').default,
    }),
    new Floor({
      level: 5,
      map: Ep5,
      backgroundImage: require('./assets/floors/Ep5.png').default,
    }),
  ],
})

const building114 = new Building({
  name: '114',
  active: true,
  spritespin: {
    framesURL: require('./assets/sprite.webp').default,
    framesPerRow: 10,
    frames: 31,
    breakpoints: [16, 24, 0, 10],
    maps: [map3, map4, map1, map2],
    qualityBreakpoints: [
      qualityBreakpoint3A_I,
      qualityBreakpoint4A_I,
      qualityBreakpoint1A_I,
      qualityBreakpoint2A_I,
    ],
    windroseInitialRotation: 105,
    reverse: true,
    frameTime: 50,
    loaderBackground: require('./assets/loader-1.jpg').default,
  },
  floors: floors114,
})

const floors114F = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: 'Jutrzenki',
    right: '',
  },
  windroseRotation: 40,
  list: [
    new Floor({
      level: 0,
      map: Fp0,
      backgroundImage: require('./assets/floors/Fp0.png').default,
    }),
    new Floor({
      level: 1,
      map: Fp1,
      backgroundImage: require('./assets/floors/Fp1.png').default,
    }),
    new Floor({
      level: 2,
      map: Fp2,
      backgroundImage: require('./assets/floors/Fp2.png').default,
    }),
    new Floor({
      level: 3,
      map: Fp3,
      backgroundImage: require('./assets/floors/Fp3.png').default,
    }),
    new Floor({
      level: 4,
      map: Fp4,
      backgroundImage: require('./assets/floors/Fp4.png').default,
    }),
    new Floor({
      level: 5,
      map: Fp5,
      backgroundImage: require('./assets/floors/Fp5.png').default,
    }),
    new Floor({
      level: 6,
      map: Fp6,
      backgroundImage: require('./assets/floors/Fp6.png').default,
    }),
  ],
})

const floors114G = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: 'Jutrzenki',
    right: '',
  },
  windroseRotation: 40,
  list: [
    new Floor({
      level: 0,
      map: Gp0,
      backgroundImage: require('./assets/floors/Gp0.png').default,
    }),
    new Floor({
      level: 1,
      map: Gp1,
      backgroundImage: require('./assets/floors/Gp1.png').default,
    }),
    new Floor({
      level: 2,
      map: Gp2,
      backgroundImage: require('./assets/floors/Gp2.png').default,
    }),
    new Floor({
      level: 3,
      map: Gp3,
      backgroundImage: require('./assets/floors/Gp3.png').default,
    }),
    new Floor({
      level: 4,
      map: Gp4,
      backgroundImage: require('./assets/floors/Gp4.png').default,
    }),
    new Floor({
      level: 5,
      map: Gp5,
      backgroundImage: require('./assets/floors/Gp5.png').default,
    }),
    new Floor({
      level: 6,
      map: Gp6,
      backgroundImage: require('./assets/floors/Gp6.png').default,
    }),
  ],
})

const floors114H = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: 'Jutrzenki',
    right: '',
  },
  windroseRotation: -15,
  list: [
    new Floor({
      level: 0,
      map: Hp0,
      backgroundImage: require('./assets/floors/Hp0.png').default,
    }),
    new Floor({
      level: 1,
      map: Hp1,
      backgroundImage: require('./assets/floors/Hp1.png').default,
    }),
    new Floor({
      level: 2,
      map: Hp2,
      backgroundImage: require('./assets/floors/Hp2.png').default,
    }),
    new Floor({
      level: 3,
      map: Hp3,
      backgroundImage: require('./assets/floors/Hp3.png').default,
    }),
    new Floor({
      level: 4,
      map: Hp4,
      backgroundImage: require('./assets/floors/Hp4.png').default,
    }),
    new Floor({
      level: 5,
      map: Hp5,
      backgroundImage: require('./assets/floors/Hp5.png').default,
    }),
  ],
})

const building114A = new Building({
  name: '114A',
  active: true,
  spritespin: {
    framesURL: require('./assets/sprite.webp').default,
    framesPerRow: 10,
    frames: 31,
    breakpoints: [16, 24, 0, 10],
    maps: [map3, map4, map1, map2],
    qualityBreakpoints: [
      qualityBreakpoint3A_I,
      qualityBreakpoint4A_I,
      qualityBreakpoint1A_I,
      qualityBreakpoint2A_I,
    ],
    windroseInitialRotation: 105,
    reverse: true,
    frameTime: 50,
    loaderBackground: require('./assets/loader-1.jpg').default,
  },
  floors: floors114A,
})

const building114B = new Building({
  name: '114B',
  active: true,
  spritespin: {
    framesURL: require('./assets/sprite.webp').default,
    framesPerRow: 10,
    frames: 31,
    breakpoints: [24, 0, 10, 16],
    maps: [map4, map1, map2, map3],
    qualityBreakpoints: [
      qualityBreakpoint4A_I,
      qualityBreakpoint1A_I,
      qualityBreakpoint2A_I,
      qualityBreakpoint3A_I,
    ],
    windroseInitialRotation: 105,
    reverse: true,
    frameTime: 50,
    loaderBackground: require('./assets/loader-2.jpg').default,
  },
  floors: floors114B,
})

const building114C = new Building({
  name: '114C',
  active: true,
  spritespin: {
    framesURL: require('./assets/sprite-3.webp').default,
    framesPerRow: 10,
    frames: 41,
    breakpoints: [24, 36, 16],
    maps: [map2A, map3A, map1A],
    qualityBreakpoints: [
      qualityBreakpoint2_III,
      qualityBreakpoint3_III,
      qualityBreakpoint1_III,
    ],
    windroseInitialRotation: 60,
    reverse: true,
    frameTime: 50,
    loaderBackground: require('./assets/loader-3.jpg').default,
  },
  floors: floors114C,
})

const building114D = new Building({
  name: '114D',
  active: true,
  spritespin: {
    framesURL: require('./assets/sprite-3.webp').default,
    framesPerRow: 10,
    frames: 41,
    breakpoints: [24, 36, 16],
    maps: [map2A, map3A, map1A],
    qualityBreakpoints: [
      qualityBreakpoint2_III,
      qualityBreakpoint3_III,
      qualityBreakpoint1_III,
    ],
    windroseInitialRotation: 60,
    reverse: true,
    frameTime: 50,
    loaderBackground: require('./assets/loader-3.jpg').default,
  },
  floors: floors114D,
})

const building114E = new Building({
  name: '114E',
  active: true,
  spritespin: {
    framesURL: require('./assets/sprite-5.webp').default,
    framesPerRow: 10,
    frames: 40,
    breakpoints: [7, 18, 29, 36],
    maps: [map1V, map2V, map3V, map4V],
    qualityBreakpoints: [
      qualityBreakpoint1_V,
      qualityBreakpoint2_V,
      qualityBreakpoint3_V,
      qualityBreakpoint4_V,
    ],
    windroseInitialRotation: 20,
    reverse: true,
    frameTime: 50,
    loaderBackground: require('./assets/V-8.jpg').default,
  },
  floors: floors114E,
})

const building114F = new Building({
  name: '114F',
  active: true,
  spritespin: {
    framesURL: require('./assets/sprite-4.webp').default,
    framesPerRow: 10,
    frames: 41,
    breakpoints: [3, 17, 35],
    maps: [map1B, map2B, map3B],
    qualityBreakpoints: [
      qualityBreakpoint1_IV,
      qualityBreakpoint2_IV,
      qualityBreakpoint3_IV,
    ],
    windroseInitialRotation: 60,
    reverse: true,
    frameTime: 50,
    loaderBackground: qualityBreakpoint1_IV,
  },
  floors: floors114F,
})

const building114G = new Building({
  name: '114G',
  active: true,
  spritespin: {
    framesURL: require('./assets/sprite-4.webp').default,
    framesPerRow: 10,
    frames: 41,
    breakpoints: [35, 3, 17],
    maps: [map3B, map1B, map2B],
    qualityBreakpoints: [
      qualityBreakpoint3_IV,
      qualityBreakpoint1_IV,
      qualityBreakpoint2_IV,
    ],
    windroseInitialRotation: 60,
    reverse: true,
    frameTime: 50,
    loaderBackground: qualityBreakpoint3_IV,
  },
  floors: floors114G,
})

const building114H = new Building({
  name: '114H',
  active: true,
  spritespin: {
    framesURL: require('./assets/sprite-5.webp').default,
    framesPerRow: 10,
    frames: 40,
    breakpoints: [18, 29, 36, 7],
    maps: [map2V, map3V, map4V, map1V],
    qualityBreakpoints: [
      qualityBreakpoint2_V,
      qualityBreakpoint3_V,
      qualityBreakpoint4_V,
      qualityBreakpoint1_V,
    ],
    windroseInitialRotation: 20,
    reverse: true,
    frameTime: 50,
    loaderBackground: require('./assets/V-19.jpg').default,
  },
  floors: floors114H,
})

const buildings = new Map()
buildings.set(building114.id, building114)
buildings.set(building114A.id, building114A)
buildings.set(building114B.id, building114B)
buildings.set(building114C.id, building114C)
buildings.set(building114D.id, building114D)
buildings.set(building114E.id, building114E)
buildings.set(building114F.id, building114F)
buildings.set(building114G.id, building114G)
buildings.set(building114H.id, building114H)
buildings.set(building106D.id, building106D)
buildings.set(building106E.id, building106E)
buildings.set(building106C.id, building106C)

const investment = new Investment({
  investmentName: 'Miasteczko Jutrzenki',
  assetsDirectory: 'miasteczko',
  buildings,
  buildingsMap: investmentMap,
  buildingsBackground: require('./assets/investment.webp').default,
  attachTooltip: () => {
    const polygon1 = document.querySelector('[data-building="114"]')
    const tooltip1 = document.querySelector('#building-114')
    const polygon2 = document.querySelector('[data-building="114A"]')
    const tooltip2 = document.querySelector('#building-114A')
    const polygon3 = document.querySelector('[data-building="114B"]')
    const tooltip3 = document.querySelector('#building-114B')
    const polygon4 = document.querySelector('[data-building="114C"]')
    const tooltip4 = document.querySelector('#building-114C')
    const polygon5 = document.querySelector('[data-building="114D"]')
    const tooltip5 = document.querySelector('#building-114D')
    const polygon6 = document.querySelector('[data-building="114F"]')
    const tooltip6 = document.querySelector('#building-114F')
    const polygon7 = document.querySelector('[data-building="114G"]')
    const tooltip7 = document.querySelector('#building-114G')
    const polygon8 = document.querySelector('[data-building="114E"]')
    const tooltip8 = document.querySelector('#building-114E')
    const polygon9 = document.querySelector('[data-building="114H"]')
    const tooltip9 = document.querySelector('#building-114H')
    const polygon10 = document.querySelector('[data-building="106D"]')
    const tooltip10 = document.querySelector('#building-106D')
    const polygon11 = document.querySelector('[data-building="H1"]')
    const tooltip11 = document.querySelector('#building-H1')
    const polygon12 = document.querySelector('[data-building="H2"]')
    const tooltip12 = document.querySelector('#building-H2')
    const polygon13 = document.querySelector('[data-building="106C"]')
    const tooltip13 = document.querySelector('#building-106C')
    const polygon14 = document.querySelector('[data-building="106E"]')
    const tooltip14 = document.querySelector('#building-106E')

    if (polygon1 && tooltip1) {
      const { top, left, width, height } = polygon1.getBoundingClientRect()
      tooltip1.style.opacity = '1'
      tooltip1.style.top = `${top - height}px`
      tooltip1.style.left = `${left + width / 2}px`
    }
    if (polygon2 && tooltip2) {
      const { top, left, width, height } = polygon2.getBoundingClientRect()
      tooltip2.style.opacity = '1'
      tooltip2.style.top = `${top - height}px`
      tooltip2.style.left = `${left + width / 2}px`
    }
    if (polygon3 && tooltip3) {
      const { top, left, width, height } = polygon3.getBoundingClientRect()
      tooltip3.style.opacity = '1'
      tooltip3.style.top = `${top - height / 4}px`
      tooltip3.style.left = `${left + width / 2}px`
    }
    if (polygon4 && tooltip4) {
      const { top, left, width, height } = polygon4.getBoundingClientRect()
      tooltip4.style.opacity = '1'
      tooltip4.style.top = `${top - height / 2}px`
      tooltip4.style.left = `${left + width / 1.5}px`
    }
    if (polygon5 && tooltip5) {
      const { top, left, width, height } = polygon5.getBoundingClientRect()
      tooltip5.style.opacity = '1'
      tooltip5.style.top = `${top - height}px`
      tooltip5.style.left = `${left + width / 2}px`
    }

    if (polygon6 && tooltip6) {
      const { top, left, width, height } = polygon6.getBoundingClientRect()
      tooltip6.style.opacity = '1'
      tooltip6.style.top = `${top - height / 1.5}px`
      tooltip6.style.left = `${left + width / 2}px`
    }
    if (polygon7 && tooltip7) {
      const { top, left, width, height } = polygon7.getBoundingClientRect()
      tooltip7.style.opacity = '1'
      tooltip7.style.top = `${top - height / 1.25}px`
      tooltip7.style.left = `${left + width / 2}px`
    }
    if (polygon8 && tooltip8) {
      const { top, left, width, height } = polygon8.getBoundingClientRect()
      tooltip8.style.opacity = '1'
      tooltip8.style.top = `${top - height / 2.2}px`
      tooltip8.style.left = `${left + width / 2}px`
    }
    if (polygon9 && tooltip9) {
      const { top, left, width, height } = polygon9.getBoundingClientRect()
      tooltip9.style.opacity = '1'
      tooltip9.style.top = `${top - height / 1.25}px`
      tooltip9.style.left = `${left + width / 2}px`
    }

    if (polygon10 && tooltip10) {
      const { top, left, width, height } = polygon10.getBoundingClientRect()
      tooltip10.style.opacity = '1'
      tooltip10.style.top = `${top - height / 3}px`
      tooltip10.style.left = `${left + width / 2.5}px`
    }

    if (polygon11 && tooltip11) {
      const { top, left, width, height } = polygon11.getBoundingClientRect()
      tooltip11.style.opacity = '1'
      tooltip11.style.top = `${top - height / 3}px`
      tooltip11.style.left = `${left + width / 2.5}px`
    }

    if (polygon12 && tooltip12) {
      const { top, left, width, height } = polygon12.getBoundingClientRect()
      tooltip12.style.opacity = '1'
      tooltip12.style.top = `${top - height / 3}px`
      tooltip12.style.left = `${left + width / 2.5}px`
    }

    if (polygon13 && tooltip13) {
      const { top, left, width, height } = polygon13.getBoundingClientRect()
      tooltip13.style.opacity = '1'
      tooltip13.style.top = `${top - height / 3}px`
      tooltip13.style.left = `${left + width / 2.5}px`
    }

    if (polygon14 && tooltip14) {
      const { top, left, width, height } = polygon14.getBoundingClientRect()
      tooltip14.style.opacity = '1'
      tooltip14.style.top = `${top - height / 2}px`
      tooltip14.style.left = `${left + width / 2.5}px`
    }
  },
})

export default investment
